import { checkAnimationStatus, degreeToRotate, getPoint, hexToRgba, initlayerPositionByCanvasScreenType, uuid, } from "../utils/common";
import { FrameWork } from "./framework";
export class TextDraw {
    x;
    y;
    w;
    h;
    // maxWidth = 0;
    resolutionType = {
        "720P": 1,
        "1080P": 1.5,
        "2K": 2,
        "4K": 3.2,
    };
    borderW = 0; // 骨架宽度
    borderH = 0; // 骨架高度
    lineArr;
    ele;
    newFramework;
    id;
    fontSize = 20;
    baseFontSize = 20;
    scaleFontSize = 20; //放大后的字体， 初始 = baseFontSize
    baseWidth;
    flowerFontWeight = 0; // 描边的粗细
    textType = "txt";
    fontFamily = "SiYuanHeiTi"; //"Microsoft YaHei";
    textAlign = "start";
    textBaseline = "middle";
    fontWeight = "normal";
    // 根据位置信息，设置图片 x，y 默认位置
    positionType = "center";
    defaultPadding = 0; // 距离边框的padding
    fillStyle = "#000000"; // 文字填充颜色
    strokeStyle = "#ffffff"; // 文字描边颜色
    shadowColor = "#000000"; // 阴影的 Color
    fillStyle16 = "#000000"; // 文字填充颜色
    strokeStyle16 = "#ffffff"; // 文字描边颜色
    shadowColor16 = "#000000"; // 阴影的 Color
    flowerText = "none"; // 'none'| 'fill' | 'stroke' | 'both'； none 不是花字， fill填充花字，stroke镂空花字，both镂空+填充花字
    isShowShadow = false; // 是否展示阴影
    shadowOffsetX = 0; // x轴偏移
    shadowOffsetY = 0; // y轴偏移
    shadowBlur = 0; // 模糊值
    isShow = true;
    lineHeight = 0; // 行高
    limitIndex = 60;
    limitWidth = 0;
    //透明度
    fillOpacity = 1; // 文字填充透明度
    strokeOpcity = 1; // 文字描边透明度
    shadowOpcity = 1; // 阴影透明度
    // color = '#ffffff';
    text;
    deawText = undefined;
    baseText = undefined;
    ctx;
    parent;
    // flag = true;
    // 动画
    annimateDuration = 0.5;
    // annimate: animationType;
    annimate = {
        in: {
            type: "none", // 无 | 移入 | 淡入 | 放大
            flag: true, // 是否已执行
            duration: this.annimateDuration,
        },
        out: {
            type: "none", // 无 | 移出 | 淡出 | 缩小
            flag: true, // 是否已执行
            duration: this.annimateDuration, // 是否已执行
        },
    };
    // showStatus='noChange' as 'in'|'out'|'noChange'; // 当前图册播放状态时 是刚入场 还是刚出场，还是一直 在隐藏 | 显示
    annimateOutFlag = true; // 隐藏时如果有动画执行动画功能
    animationScale = 1;
    // 编辑模式
    center = undefined; //center 编辑模式。可传中心
    angle = 0; // 角度
    rotate = 0; // 接口角度使用
    point;
    baseWH;
    frameWorkId = undefined;
    index;
    params = {};
    // captionFlowerPresetStyleKey = null;  // 字幕花字预设字体
    constructor(params) {
        // this.maxWidth = params.maxWidth;
        // this.id = uuid();
        this.lineArr = [];
        this.deawText = params.deawText || params.text;
        this.text = params.text;
        this.baseText = params.text;
        this.ctx = params.ctx;
        this.fontSize = params.isCreate
            ? (params.fontSize || this.fontSize) *
                this.resolutionType[params.parent.resolutionType]
            : params.fontSize || this.fontSize;
        this.baseFontSize = this.fontSize;
        this.scaleFontSize = this.fontSize;
        this.x = params.x;
        this.y = params.y;
        this.textType = params.textType || this.textType;
        this.positionType = params?.positionType || this.positionType;
        this.defaultPadding = params?.defaultPadding || this.defaultPadding;
        this.fillStyle = params.color || "#000000";
        this.strokeStyle = params.strokeColor || "#ffffff";
        this.shadowColor = params.shadowColor || "#000000"; // 阴影的 Color
        this.fillStyle16 = params.color || "#000000";
        this.strokeStyle16 = params.strokeColor || "#ffffff";
        this.shadowColor16 = params.shadowColor || "#000000"; // 阴影的 Color
        this.flowerText = params.flowerText || "none";
        this.fontWeight = params.fontWeight || "normal";
        this.flowerFontWeight = params.flowerFontWeight || 0;
        this.shadowOffsetX = params.shadowOffsetX || 0; // x轴偏移
        this.shadowOffsetY = params.shadowOffsetY || 0; // y轴偏移
        this.shadowBlur = params.shadowBlur || 0; // 模糊值
        this.isShowShadow = params.isShowShadow || false; // 是否展示阴影
        this.isShow = typeof params.isShow === "boolean" ? params.isShow : true;
        this.params = params.params || this.params;
        // 暂时删除字幕按钮
        // this.isShow = this.textType !== 'txt' ? false: typeof params.isShow === "boolean" ? params.isShow : true;
        this.setTextOpacity({
            fillOpacity: params?.fillOpacity,
            strokeOpcity: params?.strokeOpcity,
            shadowOpcity: params?.shadowOpcity,
        });
        // this.canvas = params.canvas;
        // this.callback = params.callback;
        this.parent = params.parent;
        // 编辑模式
        this.center = params?.center;
        this.angle = params?.angle || 0;
        this.point = params?.point;
        this.baseWH = params?.baseWH;
        this.id = params?.id || uuid();
        this.frameWorkId = params?.frameWorkId;
        this.index = params?.index;
        this.setLimitIndex();
        this.drwaFrame(params.callback);
    }
    setLimitIndex() {
        const limitWidth = this.parent.canvas.width - 100;
        this.limitWidth = limitWidth;
        this.limitIndex = Math.floor(limitWidth / this.fontSize);
    }
    /**
     * 设置 字体字族
     * @param family ：string
     */
    setFontFamily(family) {
        this.fontFamily = family;
    }
    /**
     * 设置字体大小
     * @param size ： number
     */
    setFontSize = (size) => {
        this.fontSize = size;
    };
    /**
     * 缩放设置字体
     */
    setFontSizeWhenScale(size) {
        this.setFontSize(size);
        this.scaleFontSize = this.fontSize;
    }
    /**
     * 设置字体颜色
     * @param color 颜色
     */
    setFontColor = (color) => {
        this.fillStyle = color;
    };
    /**
     * 修改文字
     * @returns text: string;
     */
    editText = (text) => {
        this.text = text;
        this.deawText = text;
        this.getTextWidth(false);
    };
    /**
     * 处理 color 十进制 为rgba
     */
    HEX2RGBA = () => {
        if (this.fillStyle.includes("#")) {
            this.fillStyle = hexToRgba(this.fillStyle, 1);
        }
        if (this.strokeStyle.includes("#")) {
            this.strokeStyle = hexToRgba(this.strokeStyle, 1);
        }
        if (this.shadowColor.includes("#")) {
            this.shadowColor = hexToRgba(this.shadowColor, 1);
        }
    };
    /**
     *  更新基础比例宽高 和字号
     *
     */
    updateBaswWAndHFontSize(w) {
        this.baseWidth = w;
        this.baseFontSize = this.fontSize;
    }
    /**
     * setIsShow
     * 播放时设置显示隐藏属性，同时执行动画
     */
    setIsShow(isShow) {
        const status = checkAnimationStatus(isShow, this.isShow);
        this.isShow = isShow;
        const ain = this.annimate.in;
        const aout = this.annimate.out;
        if (status === "in") {
            // 淡入
            if (ain.type === "fadeIn") {
                this.setTextColorWhenFadeIn("in");
                this.annimate.in.flag = true;
            }
            // 淡出
            if (aout.type === "fadeOut") {
                ain.type === "none" && this.setTextColorWhenFadeIn("out");
                this.annimate.out.flag = true;
                this.annimateOutFlag = true;
            }
            // 放大
            if (ain.type === "zoomIn") {
                // this.setTextColorWhenFadeIn("in");
                this.annimate.in.flag = true;
            }
            // 缩小
            if (aout.type === "zoomOut") {
                this.annimate.out.flag = true;
                this.annimateOutFlag = true;
            }
        }
    }
    /**
     * 添加动画
     * type: 'in' | 'out',   // 移入还是移出
      annimateType:  "none" | "moveIn" | "fadeIn" | "zoomIn" | "moveOut" | "fadeOut" | "zoomOut",  // 无 | 移入 | 淡入 | 放大 | 移出 | 淡出 | 缩小,
     * annimateDuration?: number; 默认 0.5
     */
    setTextAnnimate(params) {
        const { type, annimateType, annimateDuration } = params;
        this.annimate[params.type].type = annimateType;
        this.annimate[params.type].duration = annimateDuration;
        this.annimateDuration = annimateDuration;
        this.annimate[params.type].flag = true;
        // 入场动画，需要优先配置好初始属性， 比如初始是不展示，或者位置不同、或者初始方法 缩小
        if (type === "in" && annimateType === "fadeIn") {
            this.setTextColorWhenFadeIn("in");
        }
    }
    /**
     * 执行动画
     */
    drawTextAnnimate() {
        if (this.isShow) {
            if (this.annimate?.in.type !== "none" && this.annimate?.in?.flag) {
                this[this.annimate.in.type]();
            }
        }
        else {
            if (this.annimate?.out.type !== "none" && this.annimate?.out?.flag) {
                this[this.annimate.out.type]();
            }
        }
    }
    /**
     * 淡入时，初始化颜色为不透明
     */
    setTextColorWhenFadeIn(status) {
        if (this.fillStyle.includes("#")) {
            this.fillStyle = hexToRgba(this.fillStyle, 1);
        }
        if (this.strokeStyle.includes("#")) {
            this.strokeStyle = hexToRgba(this.strokeStyle, 1);
        }
        const rabaF = this.fillStyle.slice(0, -2);
        const rabaS = this.strokeStyle.slice(0, -2);
        if (status === "in") {
            this.fillStyle = (rabaF + `0)`);
            this.strokeStyle = (rabaS + `0)`);
        }
        else {
            this.fillStyle = (rabaF + `1)`);
            this.strokeStyle = (rabaS + `1)`);
        }
    }
    /**
     * 动画 ， 淡入
     * @param params
     */
    fadeIn() {
        if (this.annimate?.in &&
            this.annimate?.in?.type === "fadeIn" &&
            this.annimate?.in.flag) {
            this.annimate.in.flag = false;
            let duration = 10;
            this.HEX2RGBA();
            const baseDuration = this.annimate.in.duration;
            const lastIndexOfTag1 = this.fillStyle.lastIndexOf(",") + 1;
            const lastIndexOfTag2 = this.strokeStyle.lastIndexOf(",") + 1;
            const rabaF = this.fillStyle.slice(0, lastIndexOfTag1);
            const rabaS = this.strokeStyle.slice(0, lastIndexOfTag2);
            const st = setInterval(() => {
                if (duration <= baseDuration * 1000) {
                    const d = Number((duration / (baseDuration * 1000)).toFixed(2));
                    const color = d > 1 ? 1 : d;
                    this.fillStyle = (rabaF + `${color})`);
                    this.strokeStyle = (rabaS + `${color})`);
                    this.fillOpacity = color;
                    this.strokeOpcity = color;
                }
                else {
                    window.clearInterval(st);
                }
                duration += 10;
            }, 10);
        }
    }
    /**
     * 动画 ， 淡出
     * @param params
     */
    fadeOut() {
        if (this.annimate?.out &&
            this.annimate?.out?.type === "fadeOut" &&
            this.annimate?.out.flag) {
            let duration = 10;
            this.annimate.out.flag = false;
            const baseDuration = this.annimate.out.duration;
            this.HEX2RGBA();
            const lastIndexOfTag1 = this.fillStyle.lastIndexOf(",") + 1;
            const lastIndexOfTag2 = this.strokeStyle.lastIndexOf(",") + 1;
            const rabaF = this.fillStyle.slice(0, lastIndexOfTag1);
            const rabaS = this.strokeStyle.slice(0, lastIndexOfTag2);
            const st = setInterval(() => {
                if (duration <= baseDuration * 1000) {
                    const d = Number((duration / (baseDuration * 1000)).toFixed(2));
                    const color = d > 1 ? 1 : d;
                    this.fillStyle = (rabaF + `${1 - color})`);
                    this.strokeStyle = (rabaS + `${1 - color})`);
                    this.fillOpacity = color;
                    this.strokeOpcity = color;
                }
                else {
                    window.clearInterval(st);
                    this.annimateOutFlag = false;
                }
                duration += 10;
            }, 10);
        }
    }
    /**
     * 动画 放大
     * @param params
     */
    zoomIn() {
        if (this.annimate?.in &&
            this.annimate?.in?.type === "zoomIn" &&
            this.annimate?.in.flag) {
            // 执行50次
            let duration = 10;
            this.annimate.in.flag = false;
            const maxLarge = 0.5; // 方大最大比例
            const baseDuration = this.annimate.in.duration;
            const st = setInterval(() => {
                if (duration <= baseDuration * 1000) {
                    const d = Number((duration / (baseDuration * 1000)).toFixed(2));
                    const rd = d > 1 ? 1 : d;
                    const currentSize = Math.ceil(this.scaleFontSize + rd * maxLarge * this.scaleFontSize);
                    this.parent.setTextSize(currentSize);
                }
                else {
                    window.clearInterval(st);
                    this.annimateOutFlag = false;
                    setTimeout(() => {
                        this.parent.setTextSize(this.scaleFontSize);
                    });
                }
                duration += 10;
            }, 10);
        }
    }
    /**
     * 动画 缩小
     * @param params
     */
    zoomOut() {
        if (this.annimate?.out &&
            this.annimate?.out?.type === "zoomOut" &&
            this.annimate?.out.flag) {
            // 执行50次
            let duration = 10;
            this.annimate.out.flag = false;
            const maxLarge = 0.5; // 方大最大比例
            const baseDuration = this.annimate.in.duration;
            // const maxSize = this.scaleFontSize + (maxLarge*this.scaleFontSize);
            const st = setInterval(() => {
                if (duration <= baseDuration * 1000) {
                    const d = Number((duration / (baseDuration * 1000)).toFixed(2));
                    const rd = d > 1 ? 1 : d;
                    const currentSize = Math.ceil(this.scaleFontSize - rd * maxLarge * this.scaleFontSize);
                    this.parent.setTextSize(currentSize);
                }
                else {
                    window.clearInterval(st);
                    this.annimateOutFlag = false;
                    setTimeout(() => {
                        this.parent.setTextSize(this.scaleFontSize);
                    });
                }
                duration += 10;
            }, 10);
        }
    }
    /**
     * 移入
     * moveIn
     * 逻辑未填写
     * @param params
     */
    moveIn() {
        if (
        // true
        // this.annimate?.in &&
        // this.annimate?.in?.type === "zoomIn" &&
        this.annimate?.in.flag) {
            let duration = 10;
            this.annimate.in.flag = false;
            const maxLarge = 0.5;
            const baseDuration = this.annimate.in.duration;
            const st = setInterval(() => {
                if (duration <= baseDuration * 1000) {
                    const d = Number((duration / (baseDuration * 1000)).toFixed(2));
                    const rd = d > 1 ? 1 : d;
                    // this.animationScale = rd + maxLarge;
                }
                else {
                    window.clearInterval(st);
                    this.annimateOutFlag = false;
                }
                duration += 10;
            }, duration);
        }
    }
    /**
     * 1：修改文字的属性
     * 2：可以使用此方法把普通文字修改为花字
     */
    changeToFlowerText = (params) => {
        this.flowerText = params.flowerText || this.flowerText;
        this.textType = params.textType || this.textType;
        this.text = typeof params?.text === "string" ? params?.text : this.text;
        this.deawText = params.deawText || this.text;
        this.fillStyle = params.color || this.fillStyle;
        this.strokeStyle = params.strokeColor || this.strokeStyle;
        this.shadowColor = params.shadowColor || this.shadowColor; // 阴影的 Color
        this.fillStyle16 = params.color || this.fillStyle16;
        this.strokeStyle16 = params.strokeColor || this.strokeStyle16;
        this.shadowColor16 = params.shadowColor || this.shadowColor16; // 阴影的 Color
        this.fontWeight = params.fontWeight || this.fontWeight;
        this.flowerFontWeight =
            typeof params.flowerFontWeight === "number"
                ? params.flowerFontWeight
                : this.flowerFontWeight;
        // this.flowerFontWeight = params.flowerFontWeight && Number(params.flowerFontWeight) !== 0 ? params.flowerFontWeight : this.flowerFontWeight;
        this.fontSize =
            typeof params.fontSize === "number" ? params.fontSize : this.fontSize;
        // this.baseFontSize = this.fontSize;
        this.fontFamily = params.fontFamily || this.fontFamily;
        this.shadowOffsetX =
            params.shadowOffsetX && Number(params.shadowOffsetX) !== 0
                ? params.shadowOffsetX
                : this.shadowOffsetX; // x轴偏移
        this.shadowOffsetY =
            params.shadowOffsetY && Number(params.shadowOffsetY) !== 0
                ? params.shadowOffsetY
                : this.shadowOffsetY; // y轴偏移
        this.shadowBlur = params.shadowBlur || this.shadowBlur; // 模糊值
        this.isShow = typeof params.isShow === "boolean" ? params.isShow : true;
        this.params = { ...this.params, ...params?.params };
        // 暂时删除字幕按钮
        // this.isShow = this.textType !== 'txt' ? false: typeof params.isShow === "boolean" ? params.isShow : true;
        this.isShowShadow =
            typeof params.isShowShadow === "boolean"
                ? params.isShowShadow
                : this.isShowShadow; // 是否展示阴影
        this.setTextOpacity({
            fillOpacity: params.fillOpacity,
            strokeOpcity: params.strokeOpcity,
            shadowOpcity: params.shadowOpcity,
        });
        // this.updateBaswWAndHFontSize()
    };
    // 设置透明度
    setTextOpacity(parmas) {
        this.HEX2RGBA();
        const { fillOpacity, strokeOpcity, shadowOpcity } = parmas;
        if (typeof fillOpacity === "number") {
            const lastIndexOfTag1 = this.fillStyle.lastIndexOf(",") + 1;
            const rabaF = this.fillStyle.slice(0, lastIndexOfTag1);
            this.fillStyle = (rabaF + `${fillOpacity})`);
            this.fillOpacity = fillOpacity;
        }
        if (typeof strokeOpcity === "number") {
            const lastIndexOfTag2 = this.strokeStyle.lastIndexOf(",") + 1;
            const rabaS = this.strokeStyle.slice(0, lastIndexOfTag2);
            this.strokeStyle = (rabaS + `${strokeOpcity})`);
            this.strokeOpcity = strokeOpcity;
        }
        if (typeof shadowOpcity === "number") {
            const lastIndexOfTag3 = this.shadowColor.lastIndexOf(",") + 1;
            const rabaSd = this.shadowColor.slice(0, lastIndexOfTag3);
            this.shadowColor = (rabaSd + `${shadowOpcity})`);
            this.shadowOpcity = shadowOpcity;
        }
    }
    // 字体自适应热区及框架位置
    autoSetFrameBoxPosition = () => {
        const [x, y, w, h] = this.getTextWidth(false);
        // const lh =  h + (this.target?.self.lineArr.length * 30) - 30; // 如果设置了行高 切 行高为30时 
        const lh = h;
        if (this.newFramework) {
            this.newFramework.width = w;
            this.newFramework.height = lh;
            // 添加或者删除文字后
            // if (type === 'editText'){
            this.w = w;
            this.h = lh;
            // }
        }
        this.newFramework.getPoint();
        this.newFramework.getCenter();
        this.updateBaswWAndHFontSize(w);
        if (this.isShow) {
            this.parent.border.refresh(this.newFramework);
        }
    };
    overSizeSplitText(text) {
        const tw = this.ctx.measureText(text).width;
        const limitWidth = this.limitWidth;
        const limitIndex = this.limitIndex;
        // console.log('tw',text, tw, limitWidth)
        // 如果某一行 超宽 。。。
        if (tw > limitWidth) {
            // console.log('进入超宽')
            //  。。。 方案
            // console.log('vt', limitWidth, limitIndex)
            // const result = text.substring(0, limitIndex - 3) + '...';
            // return result
            // 。。。
            // 换行方案
            const first = text.substring(0, limitIndex);
            const right = this.overSizeSplitText(text.substring(limitIndex, text.length - 1));
            // console.log([first, right].flat())
            return [first, right].flat();
        }
        return [text];
    }
    setFontSizeByScale() {
        const scale = this.newFramework?.width / this.baseWidth;
        this.setFontSizeWhenScale(this.baseFontSize * scale);
        this.getTextWidth(false);
        this.parent.getLayerChangeAttributeCallBack();
    }
    // 获取当前文字的 width、 高、位置
    getTextWidth = (checkIsOverSize = true) => {
        this.ctx.font = `${this.fontWeight} ${this.fontSize}px ${this.fontFamily}`;
        // const limitWidth = this.parent.canvas.width - 100;
        // const limitWidth = checkIsOverSize? this.parent.canvas.width - 100 : this.newFramework.width;
        this.lineArr = [];
        const text = this.text; // this.text
        // ... 方式
        // const spTextList = text.split("\n");
        // const textArr = spTextList.map((x, i) => this.overSizeSplitText(x));
        const textArr = text.split("\n").map(x => this.overSizeSplitText(x)).flat();
        // this.text = textArr.join('\n');
        let textH = 0;
        for (let index = 0; index < textArr.length; index++) {
            const text = textArr[index];
            const textInfo = {
                text,
                width: this.ctx.measureText(text).width,
                h: (textH = index ? textH + this.fontSize : this.fontSize * 2),
                x: this.x,
                y: this.y,
            };
            this.lineArr.push(textInfo);
        }
        const newList = [...this.lineArr];
        const maxValueObject = newList.reduce((max, current) => {
            return current.width > max.width ? current : max;
        }, newList[0]);
        const maxValueObjectY = newList.reduce((max, current) => {
            return current.h > max.h ? current : max;
        }, newList[0]);
        const maxW = maxValueObject.width;
        const maxH = maxValueObjectY.h;
        const [x, y] = this.x
            ? [this.x, this.y]
            : this.positionType === "none"
                ? [this.x, this.y]
                : initlayerPositionByCanvasScreenType({
                    width: maxW,
                    height: maxH,
                    canvasDraw: this.parent,
                    positionType: this.positionType,
                    screenType: this.parent?.canvasType,
                    defaultPadding: this.defaultPadding,
                    newFramework: this.newFramework,
                });
        this.x = x;
        this.y = y;
        this.w = maxW;
        this.h = maxH;
        return [x, y, maxW, maxH];
    };
    drwaFrame = (callback) => {
        const [x, y, w, h] = this.getTextWidth();
        this.newFramework = new FrameWork({
            width: w,
            height: h,
            center: [x + w / 2, y + h / 2],
            itemType: this.textType,
            angle: this.angle || 0,
            hiddenShowC_point: true,
            hiddenShowR_point: true,
            // hiddenShowS_point: this.textType !== 'txt',
            // 编辑模式
            point: getPoint(w, h, [x + w / 2, y + h / 2]),
            baseWH: this.baseWH,
            id: this.frameWorkId,
        });
        this.baseWidth = w;
        callback({
            id: this.id,
            url: undefined,
            x,
            y,
            w: w,
            h: h,
            ele: undefined,
            newFramework: this.newFramework,
            type: "text",
            self: this,
            isShow: this.isShow,
            isShowShadow: this.isShowShadow,
        });
    };
    /**
     * 属性面板修改属性方法
     */
    updateXYAngle(params) {
        const { x, y, angle } = params;
        this.x = x;
        this.y = y;
        this.angle = angle;
        const w = this.newFramework.width;
        const h = this.newFramework.height;
        this.newFramework.updateXYAngle({
            width: w,
            height: h,
            center: [x + w / 2, y + h / 2],
            angle: degreeToRotate(angle),
        });
    }
    /**
     * 更新当前图片的位置信息
     * positionType: 'topLeft' | 'topCenter'| 'topRight' | 'left' | 'center'| 'right' | 'btmLeft' | 'btmCenter' | 'btmRight'；
     */
    changePositonByPositionType = (positionType, defaultPadding = this.defaultPadding) => {
        this.positionType = positionType;
        this.defaultPadding = defaultPadding;
        // this.ctx.font = `${this.fontWeight} ${this.fontSize}px ${this.fontFamily}`;
        // const textMetrics = this.ctx.measureText(this.deawText || this.text);
        // const width = textMetrics.width; //this.text.length * this.fontSize;
        // const height = this.fontSize * 2;
        const [ax, ay, width, height] = this.getTextWidth();
        const [x, y] = initlayerPositionByCanvasScreenType({
            width,
            height,
            canvasDraw: this.parent,
            positionType,
            defaultPadding,
            screenType: this.parent?.canvasType,
            newFramework: this.newFramework,
        });
        this.newFramework.updateXYAngle({
            width,
            height,
            center: [x + width / 2, y + height / 2],
            angle: degreeToRotate(this.angle),
        });
    };
    setXY = (x, y) => {
        this.x = x;
        this.y = y;
    };
    draw = () => {
        const { ctx } = this.parent;
        ctx.save();
        const { newFramework, animationScale } = this;
        const w = this.w;
        // const w =  newFramework.point[0][0]; // 中心点为左上时
        const [c_x, c_y] = newFramework.center;
        ctx.translate(c_x, c_y);
        ctx.rotate(newFramework.angle);
        ctx.scale(animationScale, animationScale);
        ctx.textAlign = this.textAlign;
        ctx.textBaseline = 'middle';
        ctx.fillStyle = this.fillStyle;
        ctx.font = `${this.fontWeight} ${this.fontSize}px ${this.fontFamily}`;
        const textArrLen = this.lineArr.length;
        const textList = this.lineArr.map(w => w.text);
        this.text = textList.join('\n');
        this.deawText = textList.join('\n');
        for (let index = 0; index < textArrLen; index++) {
            const textInfo = this.lineArr[index];
            const text = textInfo.text;
            const sh = this.fontSize + (this.fontSize * index);
            const H = Math.abs(newFramework.point[0][1] - newFramework.point[3][1]);
            // const height = index? sh - (H / 2) + (30 * index) : sh - (H / 2); // 设置行高
            const height = sh - (H / 2);
            // 是否展示阴影
            if (this.isShowShadow) {
                ctx.shadowColor = this.shadowColor;
                ctx.shadowOffsetX = this.shadowOffsetX;
                ctx.shadowOffsetY = this.shadowOffsetY;
                ctx.shadowBlur = this.shadowBlur;
            }
            // 花字. 镂空花字
            if (this.flowerText === "stroke") {
                ctx.lineWidth = this.flowerFontWeight;
                ctx.strokeStyle = this.strokeStyle;
                ctx.strokeText(text, -w / 2, height);
                // 花字 填充 无描边
            }
            else if (this.flowerText === "fill") {
                ctx.fillText(text, -w / 2, height);
                // 即是填充又是描边
            }
            else if (this.flowerText === "both") {
                ctx.font = `${this.fontSize}px ${this.fontFamily}`;
                // 宽度为 0 不展示边框描边
                ctx.lineWidth = this.flowerFontWeight;
                ctx.strokeStyle = this.strokeStyle;
                ctx.strokeText(text, -w / 2, height);
                ctx.fillText(text, -w / 2, height);
            }
            else {
                ctx.fillText(text, -w / 2, height);
            }
        }
        ctx.restore();
    };
}
