export class GifFrameDrawer {
    imageEle;
    frames;
    currentFrame;
    gif;
    constructor(imageEle) {
        this.imageEle = imageEle;
        this.frames = [];
        this.currentFrame = 0;
        this.loadGif();
    }
    loadGif() {
        // @ts-ignore
        this.gif = new SuperGif({ gif: this.imageEle, progressbar: false, auto_play: false });
        // Load the GIF
        this.gif.load(() => {
            this.extractFrames();
        });
    }
    extractFrames() {
        const frameCount = this.gif.get_length();
        for (let i = 0; i < frameCount; i++) {
            this.gif.move_to(i);
            const canvas = this.gif.get_canvas();
            if (canvas) {
                const tempCanvas = document.createElement('canvas');
                tempCanvas.width = canvas.width;
                tempCanvas.height = canvas.height;
                const tempContext = tempCanvas.getContext('2d');
                if (tempContext) {
                    tempContext.drawImage(canvas, 0, 0);
                }
                this.frames.push(tempCanvas);
                // this.displayFrame(tempCanvas, i);
            }
        }
    }
    displayFrame(canvas, index) {
        const img = document.createElement('img');
        img.src = canvas.toDataURL();
        img.style.margin = '5px';
        img.alt = `Frame ${index}`;
        document.body.appendChild(img);
    }
    setGifFrame(frameIndex) {
        this.currentFrame = Math.floor(frameIndex);
    }
    drawFrame(context, x, y, width, height, baseImage) {
        const frameCanvas = this.frames[this.currentFrame];
        const lastFrameCanvas = this.frames[this.frames.length - 1];
        if (frameCanvas) {
            context.drawImage(frameCanvas, x, y, width, height);
        }
        else if (lastFrameCanvas) {
            context.drawImage(lastFrameCanvas, x, y, width, height);
        }
        else if (baseImage) {
            context.drawImage(baseImage, x, y, width, height);
        }
    }
}
