import { GifFrameDrawer } from "@/lib/CanvasDraw/utils/GifFrameDrawer";
export class GifFrameDrawerMange {
    urlTogifFrameDrawer;
    keyMap = {};
    constructor() {
        this.urlTogifFrameDrawer = {};
        this.keyMap = {};
    }
    setKeyMap(obj) {
        this.keyMap = obj || {};
        for (const key in this.urlTogifFrameDrawer) {
            const img = new Image();
            if (this.keyMap[key]) {
                img.src = this.keyMap[key].iconUrl;
            }
            else {
                img.src = key;
            }
            img.setAttribute("crossOrigin", "anonymous");
            this.urlTogifFrameDrawer[key] = new GifFrameDrawer(img);
        }
    }
    async checkIsLoadImageSuccess(key, obj) {
        this.keyMap = obj || {};
        const img = new Image();
        if (this.keyMap[key]) {
            img.src = this.keyMap[key].iconUrl;
        }
        else {
            img.src = key;
        }
        img.setAttribute("crossOrigin", "anonymous");
        await new Promise((resolve, reject) => {
            img.onload = function () {
                return resolve(img);
            };
        });
        this.urlTogifFrameDrawer[key] = new GifFrameDrawer(img);
    }
    addGifFrameDrawer(key) {
        if (!this.urlTogifFrameDrawer[key]) {
            const img = new Image();
            if (this.keyMap[key]) {
                img.src = this.keyMap[key].iconUrl;
            }
            else {
                img.src = key;
            }
            img.setAttribute("crossOrigin", "anonymous");
            this.urlTogifFrameDrawer[key] = new GifFrameDrawer(img);
        }
        return this.urlTogifFrameDrawer[key];
    }
    getGifFrameDrawer(key) {
        return this.urlTogifFrameDrawer[key];
    }
    getKeyMap() {
        return this.keyMap;
    }
}
