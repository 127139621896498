import { uuid, replaceBlank } from "../../Utils/common";
import { doItemsOverlap } from "../utils/common";
export class TimelineItem {
    id = uuid();
    type = "txt";
    visible = true;
    isTimeLineItem = true;
    durationModifiable = true;
    startTime;
    time;
    maxTime;
    totalFrame;
    pixelsPerFrame;
    width;
    dy_total;
    y;
    height;
    resizeHandleWidth = 8;
    resizeHandleVisible = true;
    x;
    isSelected = false;
    isMoveXchange = true;
    isChangeY = true;
    isTimeChange = true;
    cloesButtonRadius = 6;
    icon = "";
    activeIcon = "";
    txt = "";
    txtFontSize = 10;
    txtColor = "#000";
    bcColor = "#e6e6fb";
    borderRadius = 3;
    rowId = "";
    drawId = "";
    brotherId = undefined;
    brothers = [];
    group = [];
    row = [];
    timeLineRow;
    friendIds = [];
    muted = false;
    showDraw = true;
    status = "none";
    params = {};
    sideText;
    interval = [-1, -1];
    isDelete;
    isShowDragTag = true;
    constructor(startTime, time, params = {}) {
        this.startTime = Math.floor(startTime);
        this.time = Math.floor(time);
        Object.keys(params).forEach((key) => {
            this[key] = params[key];
        });
        this.brothers = [];
        this.row = [];
        this.isSelected = false;
        this.status = "none";
    }
    setPixelsPerSecond(pixelsPerFrame, totalFrame) {
        // if (this.time * this.pixelsPerFrame < 80) {
        //   return
        // }
        this.pixelsPerFrame = pixelsPerFrame;
        if (totalFrame !== undefined) {
            this.totalFrame = totalFrame;
        }
        this.x = this.startTime * this.pixelsPerFrame;
        this.width = this.time * this.pixelsPerFrame;
        this.updateBrothers();
    }
    getEndTime() {
        return this.startTime + this.time;
    }
    getY() {
        if (!this.isSelected) {
            this.dy_total = 0;
        }
        if (!this.isChangeY) {
            return this.y;
        }
        return this.y + (this.dy_total || 0);
    }
    getActListInCurAct(list, current, fps) {
        const act = list.find(({ inTime, endTime }) => {
            return (inTime * fps + this.startTime <= current &&
                endTime * fps + this.startTime >= current);
        });
        if (act) {
            return {
                ...act,
                frameIndex: current - Math.floor(act.inTime * fps) - this.startTime,
            };
        }
    }
    getCenter() {
        return {
            x: this.x + this.width / 2,
            y: this.getY() + this.height / 2,
        };
    }
    moveY(dy) {
        this.dy_total += dy;
    }
    moveTimeLineX(timeDiff, updateCallBack) {
        if (!this.isMoveXchange || this.status !== "move")
            return;
        const time = this.time;
        const startTime = this.startTime;
        const endTime = startTime + time;
        const newTime = time + timeDiff;
        const newStartTime = startTime + timeDiff;
        const newEndTime = newStartTime + newTime;
        if (newStartTime < 0) {
            timeDiff = -startTime;
        }
        this.startTime += timeDiff;
        if (this.row.filter((item) => doItemsOverlap(item, this)).length &&
            !this.isChangeY) {
            this.startTime -= timeDiff;
            timeDiff = 0;
        }
        if (this.interval[0] != -1 && this.startTime < this.interval[0]) {
            this.startTime = this.interval[0];
            timeDiff = this.startTime - startTime;
        }
        if (this.interval[1] != -1 && this.getEndTime() > this.interval[1]) {
            this.startTime = this.interval[1] - this.time;
            timeDiff = this.startTime - startTime;
        }
        this.timeLineRow.children.forEach((row) => {
            row.updateInterval();
            row.items
                .sort((a, b) => {
                if (timeDiff < 0) {
                    return a.startTime - b.startTime;
                }
                return b.startTime - a.startTime;
            })
                .forEach((item) => {
                item.moveTimeLineX(timeDiff);
            });
        });
        updateCallBack && updateCallBack(timeDiff);
        this.updateBrothers();
    }
    setStartTime(timeDiff, updateCallBack) {
        if (!this.isTimeChange)
            return;
        const time = this.time;
        const startTime = this.startTime;
        const endTime = startTime + time;
        const newTime = time - timeDiff;
        const newStartTime = startTime + timeDiff;
        const newEndTime = newStartTime + newTime;
        if (this.maxTime && newTime > this.maxTime)
            return;
        if (newStartTime < 0) {
            timeDiff = -startTime;
        }
        else if (newStartTime >= endTime) {
            timeDiff = endTime - startTime;
        }
        this.startTime += timeDiff;
        this.time -= timeDiff;
        if (this.row.filter((item) => doItemsOverlap(item, this)).length) {
            this.startTime -= timeDiff;
            this.time += timeDiff;
            timeDiff = 0;
        }
        if (this.interval[0] != -1 && this.startTime < this.interval[0]) {
            this.startTime = this.interval[0];
            timeDiff = this.startTime - startTime;
            this.time = time - timeDiff;
        }
        const childrenRowBox = this.timeLineRow.children[0]?.getBox();
        if (childrenRowBox && this.startTime > childrenRowBox.startTime) {
            this.startTime = childrenRowBox.startTime;
            timeDiff = this.startTime - startTime;
            this.time = time - timeDiff;
        }
        this.timeLineRow.children.forEach((row) => {
            row.updateInterval();
        });
        updateCallBack && updateCallBack(timeDiff);
        this.updateBrothers();
    }
    setEndTime(timeDiff, updateCallBack) {
        if (!this.isTimeChange)
            return;
        const time = this.time;
        const startTime = this.startTime;
        const endTime = startTime + time;
        const newTime = time + timeDiff;
        const newEndTime = newTime + startTime;
        if (this.maxTime && newTime > this.maxTime) {
            return;
        }
        if (newEndTime <= startTime) {
            timeDiff = -time;
        }
        this.time += timeDiff;
        if (this.row.filter((item) => doItemsOverlap(item, this)).length) {
            this.time -= timeDiff;
            timeDiff = 0;
        }
        if (this.interval[1] != -1 && this.getEndTime() > this.interval[1]) {
            this.time = this.interval[1] - this.startTime;
            timeDiff = this.time - time;
        }
        const childrenRowBox = this.timeLineRow.children[0]?.getBox();
        if (childrenRowBox && this.getEndTime() < childrenRowBox.endTime) {
            this.time = childrenRowBox.endTime - this.startTime;
            timeDiff = this.time - time;
        }
        this.timeLineRow.children.forEach((row) => {
            row.updateInterval();
        });
        updateCallBack && updateCallBack(timeDiff);
        this.updateBrothers();
    }
    resetTime() {
        const index = String(this.startTime).indexOf(".");
        if (index !== -1) {
            this.startTime = Math.floor(this.startTime);
            this.time = Math.floor(this.time);
        }
    }
    hidden() {
        this.visible = false;
    }
    show() {
        this.visible = true;
    }
    draw(ctx) {
        if (this.visible === false) {
            return;
        }
        this.setPixelsPerSecond(this.pixelsPerFrame);
        const lineWidth = 2; // 边框宽度
        const itemY = this.getY();
        // 绘制矩形主体
        ctx.fillStyle = this.bcColor; // 主体颜色
        ctx.strokeStyle = this.isSelected ? "#6962FF" : this.bcColor; // 选中时的边框颜色
        ctx.lineWidth = lineWidth; // 边框宽度
        ctx.beginPath();
        ctx.roundRect(this.x, itemY, this.width, this.height, this.borderRadius);
        ctx.fill();
        if (this.isSelected)
            ctx.stroke();
        ctx.closePath();
        if (this.isSelected) {
            if (this.resizeHandleVisible) {
                const resizeHandleHeight = this.height;
                const resizeHandleY = itemY + (this.height - resizeHandleHeight) / 2;
                this.drawResizeHandle(ctx, this.x, resizeHandleY, resizeHandleHeight);
                this.drawResizeHandle(ctx, this.x + this.width - this.resizeHandleWidth, resizeHandleY, resizeHandleHeight);
            }
        }
        // 绘制文本
        ctx.fillStyle = this.txtColor; // 文字颜色
        ctx.font = `${this.txtFontSize}px Arial`; // 文字样式
        // 清空以前的绘制文本缓存
        let ellipsisAdded = false;
        const textMargin = this.resizeHandleWidth + 6; // 文字与边框的间距
        // 逐字遍历文本
        if (this.params?.tts?.txtWithTimes?.length) {
            for (let i = 0; i < this.params?.tts?.txtWithTimes.length; i++) {
                const textObj = this.params?.tts?.txtWithTimes[i];
                const relativeTime = textObj.time + this.startTime;
                const textX = relativeTime * this.pixelsPerFrame; // 根据相对时间计算文本X位置
                const textWidth = ctx.measureText(textObj.char).width;
                // 检查文本是否超出时间线宽度
                if (textX + textWidth > this.x + this.width) {
                    if (!ellipsisAdded) {
                        ellipsisAdded = true;
                    }
                    break;
                }
                // 绘制字符
                ctx.fillText(ellipsisAdded ? '' : textObj.char, textX, itemY + this.height / 2 + 4);
            }
        }
        else {
            const textX = this.x + textMargin; // 计算文本的 X 坐标，使其居中
            const textY = itemY + this.height / 2 + 4; // 计算文本的 Y 坐标，稍微向下调整以居中
            let drawText = replaceBlank(this.txt); // 预定文本
            let textWidth = ctx.measureText(drawText).width; // 计算文本宽度
            const maxTextWidth = this.width - 2 * textMargin; // 最大文本宽度
            // 如果文本宽度超出最大宽度，则截断文本
            while (textWidth > maxTextWidth && drawText.length > 0) {
                drawText = drawText.substring(0, drawText.length - 1); // 移除最后一个字符
                textWidth = ctx.measureText(drawText + "...").width; // 重新计算宽度，包含省略号
            }
            if (drawText.length < this.txt.length) {
                drawText += "..."; // 添加省略号
            }
            ctx.fillText(drawText, textX, textY);
        }
    }
    drawCloseIcon(ctx, x, y) {
        const radius = this.cloesButtonRadius; // 关闭图标的半径
        const centerX = x; // 计算圆心X坐标
        const centerY = y; // 计算圆心Y坐标
        // 绘制圆形背景
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, 0, Math.PI * 2, false);
        ctx.fillStyle = "#fff"; // 背景色
        ctx.fill();
        // 绘制“X”形状
        const crossLength = 5; // “X”形状的对角线长度
        ctx.moveTo(centerX - crossLength / 2, centerY - crossLength / 2);
        ctx.lineTo(centerX + crossLength / 2, centerY + crossLength / 2);
        ctx.moveTo(centerX + crossLength / 2, centerY - crossLength / 2);
        ctx.lineTo(centerX - crossLength / 2, centerY + crossLength / 2);
        ctx.strokeStyle = "#43465E"; // “X”形状的颜色
        ctx.lineWidth = 1; // “X”形状的线条宽度
        ctx.stroke();
        // 添加圆形边框
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, 0, Math.PI * 2, false);
        ctx.strokeStyle = "#43465E"; // 边框颜色
        ctx.lineWidth = 1; // 边框宽度
        ctx.stroke();
        ctx.closePath();
    }
    // drawResizeHandle(
    //   ctx: CanvasRenderingContext2D,
    //   x: number,
    //   y: number,
    //   resizeHandleHeight: number,
    //   resizeHandleborderRadius: number = 2
    // ): void {
    //   // 传值是否需要显示 手柄
    //   if (this.isShowDragTag) {
    //     ctx.beginPath();
    //     ctx.lineWidth = 0; // 边框宽度
    //     // 边框颜色
    //     ctx.strokeStyle = "#6962FF";
    //     ctx.fillStyle = "#fff";
    //     // ctx.
    //     ctx.roundRect(
    //       x,
    //       y,
    //       this.resizeHandleWidth,
    //       resizeHandleHeight,
    //       resizeHandleborderRadius
    //     );
    //     ctx.fill();
    //     ctx.stroke();
    //     ctx.closePath();
    //   }
    // }
    drawResizeHandle(ctx, x, y, resizeHandleHeight = 8, // 高度 8px
    resizeHandleborderRadius = 2) {
        if (this.isShowDragTag) {
            ctx.beginPath();
            ctx.lineWidth = 0;
            ctx.strokeStyle = "#6962FF";
            ctx.fillStyle = "#6962FF";
            // 第一步：绘制带圆角的外部矩形，用于边框
            ctx.roundRect(x, y, this.resizeHandleWidth, resizeHandleHeight, resizeHandleborderRadius);
            ctx.fill();
            ctx.stroke();
            ctx.closePath();
            // 第二步：绘制内层的填充矩形，略小于边框矩形
            ctx.beginPath();
            ctx.lineWidth = 0;
            ctx.strokeStyle = "#fff";
            ctx.fillStyle = "#fff";
            ctx.roundRect(x + 4, y + 10, 0, 10);
            ctx.fill();
            ctx.stroke();
            ctx.closePath();
        }
    }
    getCloseBtnCenter() {
        return {
            x: this.x + this.width - 4,
            y: this.y + 4,
        };
    }
    /**
     *
     * @param offsetX 窗口x轴偏移量
     * @param viewWidth 窗口宽度
     * @param direction 方向 1: 向右 -1: 向左
     * @returns { isBeyondLeft: boolean; isBeyondRight: boolean } 是否超出左右边界
     */
    isBeyondTimelineBoundaries(offsetX, viewWidth, direction) {
        const itemLeftEdge = this.x;
        const itemRightEdge = this.x + this.width;
        const viewPortMargin = this.width;
        let isBeyondLeft = false;
        let isBeyondRight = false;
        const margin = {
            left: itemLeftEdge - offsetX,
            right: offsetX + viewWidth - itemRightEdge,
        };
        if (margin.left <= 0 && direction === -1) {
            isBeyondLeft = true;
        }
        if (margin.right <= 0 && direction === 1) {
            isBeyondRight = true;
        }
        return {
            isBeyondLeft,
            isBeyondRight,
        };
    }
    isCloseButtonClicked(mouseX, mouseY) {
        const { x, y } = this.getCloseBtnCenter();
        const radius = this.cloesButtonRadius; // 关闭按钮的半径
        // 计算鼠标点击位置与关闭按钮圆心的距离
        const distance = Math.sqrt(Math.pow(mouseX - x, 2) + Math.pow(mouseY - y, 2));
        // 如果距离小于半径，则点击在按钮上
        return distance <= radius;
    }
    isOnLeftHandle(x) {
        // 增大拖拽 触发范围  新增(- this.resizeHandleWidth)
        return this.isShowDragTag
            ? x >= this.x - this.resizeHandleWidth &&
                x <= this.x + this.resizeHandleWidth
            : false;
    }
    isOnRightHandle(x) {
        return this.isShowDragTag
            ? x >= this.x + this.width - this.resizeHandleWidth &&
                x <= this.x + this.width + this.resizeHandleWidth // 增大拖拽 触发范围  新增(+ this.resizeHandleWidth)
            : false;
    }
    addBrother(brother) {
        if (this.brothers.indexOf(brother) !== -1)
            return;
        this.brothers.push(brother);
    }
    updateBrothers() {
        this.brothers.forEach((item) => {
            item.x = this.x;
            item.width = this.width;
            item.time = this.time;
            item.startTime = this.startTime;
        });
    }
}
// 如果 CanvasRenderingContext2D 没有 roundRect 方法，你需要扩展它来支持绘制圆角矩形
if (!CanvasRenderingContext2D.prototype.roundRect) {
    CanvasRenderingContext2D.prototype.roundRect = function (x, y, width, height, radius) {
        this.beginPath();
        this.moveTo(x + radius, y);
        this.lineTo(x + width - radius, y);
        this.quadraticCurveTo(x + width, y, x + width, y + radius);
        this.lineTo(x + width, y + height - radius);
        this.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        this.lineTo(x + radius, y + height);
        this.quadraticCurveTo(x, y + height, x, y + height - radius);
        this.lineTo(x, y + radius);
        this.quadraticCurveTo(x, y, x + radius, y);
        this.closePath();
    };
}
