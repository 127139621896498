import { MyHistory } from "./History";
import { GifFrameDrawerMange } from "./GifFrameDrawerMange";
import { getMediaDuration, findInterval } from "@/lib/Utils/common";
import { uuid } from "@/lib/CanvasDraw/utils/common";
import EventEmitter from "@/lib/Utils/EventEmitter";
import { Timeline, TimelineRow, TimelineItem } from "@/lib/Timeline/index";
import Txt from "@/assets/images/timeLIne/Txt.svg";
import Image from "@/assets/images/timeLIne/Image.svg";
import Music from "@/assets/images/timeLIne/Music.svg";
import Video from "@/assets/images/timeLIne/Video.svg";
const defindColumn = [
    {
        name: "文字",
        type: "txt",
        icon: Txt,
        activeIcon: Txt,
        label: "文字",
        bcColor: " #56D237",
        txtColor: "#fff",
        sideText: '文本',
    },
    {
        name: "图片",
        type: "image",
        icon: Image,
        activeIcon: Image,
        label: "图片",
        bcColor: "#37A3D2",
        txtColor: "#fff",
        sideText: '贴图',
    },
    {
        name: "动图",
        type: "gif",
        icon: Image,
        activeIcon: Image,
        label: "动图",
        bcColor: "#DDF1FF",
        txtColor: "#3474D7",
        sideText: '动图',
    },
    {
        name: "音频",
        type: "audio",
        icon: Music,
        activeIcon: Music,
        label: "音频",
        bcColor: "#7237D2",
        txtColor: "#fff",
        sideText: '音乐',
    },
    {
        name: "视频",
        type: "video",
        icon: Video,
        activeIcon: Video,
        label: "视频",
        bcColor: "#37D29A",
        txtColor: "#fff",
        sideText: '视频',
    },
];
const urlToMedium = {};
const lineItemIdToMedium = {};
const updateMediumStatus = (currentTime, currentLineItems, playing, fps = 30) => {
    const idToLineItem = currentLineItems.reduce((idToLineItem, lineItem, i) => {
        idToLineItem[lineItem.id] = lineItem;
        return idToLineItem;
    }, {});
    Object.keys(lineItemIdToMedium).forEach((lineItemId) => {
        const medium = lineItemIdToMedium[lineItemId];
        const lineItem = idToLineItem[lineItemId];
        if (!medium)
            return;
        if (!lineItem) {
            medium.pause();
            return;
        }
        medium.muted = lineItem.muted;
        const timeLineMediumCF = currentTime - lineItem.startTime;
        const mediumCF = Math.floor(medium.currentTime * fps);
        const mediumTF = Math.floor(medium.duration * fps);
        const isUpdateMediumTime = Math.abs(mediumCF - timeLineMediumCF) > 5;
        if (isUpdateMediumTime) {
            medium.currentTime = timeLineMediumCF / fps;
        }
        if (playing && timeLineMediumCF < mediumTF) {
            if (medium.paused) {
                medium.volume = typeof lineItem.params.volume === 'number' ? lineItem.params.volume : 0.5;
                medium.play();
            }
        }
        else {
            if (!medium.paused) {
                medium.pause();
            }
        }
    });
};
export class VideoIdea extends EventEmitter {
    canvasDraw;
    timeLine;
    column;
    history = new MyHistory();
    gifFrameDrawerMange;
    drawIdToObj = {};
    ttsRequest = async (url) => fetch(url).then((res) => res.json());
    imgRequest;
    audioRequest;
    videoRequest;
    constructor(canvasDraw, timeLine) {
        super();
        this.canvasDraw = canvasDraw;
        this.timeLine = timeLine;
        this.timeLine.setHistory(this.history);
        this.gifFrameDrawerMange = new GifFrameDrawerMange();
        const onObjDataChange = () => {
            this.emit("OBJ_DATA_CHANGE", this.export());
        };
        // 编辑器删除 图层
        this.canvasDraw.on("DELETE_EVEN", (draw) => {
            this.drawIdToObj[draw.id].lineItem.forEach((lineItem) => {
                this.removeLineItemId(lineItem.id);
            });
            this.emit("DELETE_EVEN", this.drawIdToObj[draw.id]);
            // 如果移除的是数字人，则判断是否需要移除所有表情和动作
            this.removeManAndEmoteAndAnimation(draw);
        });
        this.canvasDraw.on("SELECT_EVEN", (draw) => {
            const lineItem = this.drawIdToObj[draw.id]?.lineItem;
            const currentTime = this.timeLine.getCurrentTimeF();
            let lineItemList = lineItem;
            // if (draw.self?.textType === 'caption-ppt' || draw.self?.textType === 'caption') {
            //   lineItemList = lineItem.filter(w => w.txt === draw.self.text);
            // }
            const firstTimeLineItem = lineItemList.filter(w => w.startTime <= currentTime && w.getEndTime() >= currentTime)[0] || lineItemList[0];
            const newItem = this.setCurrentlineItemToCanvasDraw(firstTimeLineItem);
            lineItemList && firstTimeLineItem && this.timeLine.selectItem([firstTimeLineItem], false);
            this.canvasDraw.selectedTarget(draw, false, newItem);
            this.emit("SELECT_EVEN", this.drawIdToObj[draw.id]);
        });
        // 认为没有选择当前任务元素图层。则取消图层选中
        this.canvasDraw.on("NO-CLICK-LAYER", (params) => {
            this.timeLine.cancalSelectItem();
        });
        this.timeLine.on(Timeline.EVENT_MAP.onCaptionSwitch, (value) => {
            this.canvasDraw.setCaptionSwitch(value);
            this.emit("ON_CAPTION_SWITCH", value);
        });
        // 时间轴删除图层
        this.timeLine.on(Timeline.EVENT_MAP.delete, (lineItem) => {
            // 如果移除的是数字人，则判断是否需要移除所有表情和动作
            const obj = this.getLineItemIdToObj(lineItem.id);
            this.emit("DELETE_EVEN", obj);
            this.removeLineItemId(lineItem.id);
            this.removeManAndEmoteAndAnimation(obj.draw);
        });
        this.timeLine.on(Timeline.EVENT_MAP.select, (lineItems) => {
            const lineItem = lineItems[0];
            const drawId = lineItem.drawId;
            const draw = this.drawIdToObj[drawId]?.draw;
            this.emit("SELECT_EVEN", this.drawIdToObj[draw.id]);
            if (draw.type !== "none") {
                this.canvasDraw.editText(lineItem.txt, draw.id, false);
                setTimeout(() => {
                    const newItem = this.setCurrentlineItemToCanvasDraw(lineItem);
                    this.canvasDraw.selectedTarget(draw, false, newItem);
                }, 100);
            }
            else {
                this.canvasDraw.removeSelectedTarget();
            }
        });
        this.timeLine.on(Timeline.EVENT_MAP.update, (currentTime) => {
            this.updateCurrentTime(currentTime);
        });
        this.timeLine.on(Timeline.EVENT_MAP.onplay, (currentTime) => {
            this.updateCurrentTime(currentTime);
            this.emit("ON_PLAY", 'play');
        });
        this.timeLine.on(Timeline.EVENT_MAP.onpause, (currentTime) => {
            this.updateCurrentTime(currentTime);
            this.emit("ON_PAUSE", 'pause');
        });
        // 回退前进相关事件监听   ----   开始
        const insertTem = () => {
            const tem = this.export();
            this.handleInsertTem(tem);
            // const currentTime = this.timeLine.getCurrentTimeF();
            // const temObj = {
            //   currentTime,
            //   tem,
            // };
            // this.history.insert(JSON.stringify(temObj));
            onObjDataChange();
        };
        this.timeLine.on(Timeline.EVENT_MAP.onback, this.onback);
        this.timeLine.on(Timeline.EVENT_MAP.onforward, this.onforward);
        // 历史操作插入事件。
        this.canvasDraw.on("DRAW_CHANGE_EVEN", insertTem);
        this.timeLine.on(Timeline.EVENT_MAP.onTimelineItemChange, insertTem);
        // 回退前进相关事件监听   ----   结束
        this.column = [];
    }
    handleInsertTem(data) {
        const currentTime = this.timeLine.getCurrentTimeF();
        const temObj = {
            currentTime,
            tem: data,
        };
        this.history.insert(JSON.stringify(temObj));
    }
    changeTimeLineIsScrollY(value) {
        this.timeLine.isScrollY = value;
    }
    chengeTTSText(text, drawId, timeLineId) {
        const draw = this.drawIdToObj[drawId];
        if (timeLineId) {
            draw.lineItem.forEach((lineItem) => {
                if (timeLineId && lineItem.id === timeLineId) {
                    lineItem.txt = text;
                    draw.params.text = text;
                }
            });
        }
        else if (draw.lineItem.length === 1) {
            draw.lineItem[0].txt = text;
            draw.params.text = text;
        }
        const currentTime = this.timeLine.getCurrentTimeF();
        this.updateCurrentTime(currentTime);
    }
    /**
     * 字幕 显示隐藏
     * @param value boolean
     */
    setCaptionSwitchValue(value) {
        if (this.timeLine) {
            this.timeLine.setCaptionSwitchValue(value);
            this.canvasDraw.setCaptionSwitch(value);
        }
    }
    onback() {
        const temObjStr = this.history.back();
        if (!temObjStr) {
            return;
        }
        const { currentTime, tem } = JSON.parse(temObjStr);
        this.import(JSON.stringify(tem), false);
        setTimeout(() => {
            this.timeLine.setCurrentTime(currentTime);
        }, 100);
        this.emit("OBJ_DATA_CHANGE", this.export());
    }
    onforward() {
        const temObjStr = this.history.forward();
        if (!temObjStr) {
            return;
        }
        const { currentTime, tem } = JSON.parse(temObjStr);
        this.import(JSON.stringify(tem), false);
        setTimeout(() => {
            this.timeLine.setCurrentTime(currentTime);
        }, 100);
        this.emit("OBJ_DATA_CHANGE", this.export());
    }
    setCurrentlineItemToCanvasDraw(lineItem) {
        const newItem = { ...lineItem };
        const itemFriend = newItem.friendIds?.map(w => {
            let obj = undefined;
            Object.values(this.drawIdToObj).forEach(x => {
                if (x.lineItem[0].id === w) {
                    obj = x;
                }
            });
            return obj;
        });
        newItem.friend = itemFriend;
        return newItem;
    }
    /**
   * 控制音频音量
   * @param id  id
   * @param volume 0-1 代表 (0% - 100%)
   */
    contorlAudioVolume = (id, volume) => {
        if (id && lineItemIdToMedium[id]) {
            lineItemIdToMedium[id].volume = volume;
            const lineItem = this.getLineItemIdToLineItem(id);
            lineItem.params.volume = volume;
            //  this.timeLine.emit(Timeline.EVENT_MAP.onTimelineItemChange);
        }
    };
    /**
     *  // 更改 视频的 音量 0 - 1; (0.5 = 50%)
     * @param id
     * @param volume 音量 0 - 1; (0.5 = 50%)
     *
     */
    changeVideoEleVolume = (id, volume) => {
        if (id && lineItemIdToMedium[id]) {
            this.canvasDraw.changeVideoEleVolume(volume);
            lineItemIdToMedium[id].volume = volume;
            const lineItem = this.getLineItemIdToLineItem(id);
            lineItem.params.volume = volume;
            //  this.timeLine.emit(Timeline.EVENT_MAP.onTimelineItemChange);
        }
    };
    /**
     * remove Man and / or emote and animation
     * 溢出 数字人 或者/和 全部动作和表情
     */
    removeManAndEmoteAndAnimation(draw) {
        if (draw && draw?.layerType === 'digitalMan') {
            const objList = Object.values(this.drawIdToObj).filter(x => x.id !== draw.id);
            const hasOtherMan = objList.some(w => w.draw.layerType === 'digitalMan');
            if (!hasOtherMan) {
                const emoteAndAnimationList = objList.filter(x => x.params.type === "animation" || x.params.type === "emote");
                emoteAndAnimationList.forEach(w => {
                    (w.lineItem || []).forEach(m => {
                        this.removeLineItemId(m?.id);
                    });
                });
            }
        }
    }
    removeLineItemId(timeLineId) {
        queueMicrotask(() => {
            const obj = this.getLineItemIdToObj(timeLineId);
            if (!obj)
                return;
            const index = obj.lineItem.findIndex((lineItem) => lineItem.id === timeLineId);
            const lineItem = obj?.lineItem.splice(index, 1)[0];
            lineItem.friendIds.forEach((friendId) => {
                this.removeLineItemId(friendId);
            });
            this.timeLine.removeItem([lineItem], false, false);
            if (obj?.lineItem.length === 0) {
                this.canvasDraw.deleteItemFromList(obj.id, false);
                delete this.drawIdToObj[obj.id];
            }
        });
    }
    updateCurrentTime(currentTime) {
        const timeItems = this.timeLine.getCurrentTimeItems(currentTime);
        const currentDrawIds = timeItems.map((item) => item.drawId);
        const fps = this.timeLine.getFps();
        const actOrEmo = timeItems.reduce((actOrEmo, item) => {
            if (actOrEmo) {
                return actOrEmo;
            }
            const act = item.getActListInCurAct(item.params.actList || [], currentTime, fps);
            if (act) {
                return act;
            }
            const emo = item.getActListInCurAct(item.params.emojiList || [], currentTime, fps);
            if (emo) {
                return emo;
            }
            return null;
        }, null);
        Object.keys(this.drawIdToObj).forEach((drawId) => {
            const drawIdToObj = this.drawIdToObj[drawId];
            const { lineItem, draw, params, id } = drawIdToObj;
            const isCurrent = currentDrawIds.includes(drawId);
            const currentLineItems = lineItem.filter((lineItem) => timeItems.includes(lineItem));
            if (!draw?.isNoDraw) {
                const timeing = findInterval(currentTime, lineItem.map((lineItem) => {
                    return [lineItem.startTime, lineItem.getEndTime()];
                }));
                const lineItemShowDraw = currentLineItems.filter((lineItem) => lineItem.showDraw).length > 0;
                this.canvasDraw.showOrHideLayer({
                    id: drawId,
                    isShow: isCurrent && lineItemShowDraw,
                    timeing, // 'in' | 'start' | 'end'
                });
                // 检测是否为 digitalMan draw
                if (draw?.layerType === "digitalMan") {
                    if (actOrEmo) {
                        let gifFrameDrawer = this.gifFrameDrawerMange.getGifFrameDrawer(actOrEmo.gifType || actOrEmo.gifUrl);
                        if (!gifFrameDrawer) {
                            gifFrameDrawer = this.gifFrameDrawerMange.addGifFrameDrawer(actOrEmo.gifType || actOrEmo.gifUrl);
                        }
                        this.canvasDraw.executeDrawFun(draw.id, 'addGifFrameDrawer', gifFrameDrawer);
                        this.canvasDraw.executeDrawFun(draw.id, 'setGifFrameDrawer', actOrEmo.frameIndex);
                    }
                    else {
                        this.canvasDraw.executeDrawFun(draw.id, 'removeGifFrameDrawer');
                    }
                }
            }
            if (currentLineItems.length === 0)
                return;
            if (draw.fontFamily || draw?.self?.fontFamily) {
                const text = (draw?.textType || draw?.self?.textType).includes('caption') ? currentLineItems[0].txt : draw.text;
                // @ts-ignore
                this.canvasDraw.editText(text, draw.id, false);
            }
        });
        updateMediumStatus(currentTime, timeItems, this.timeLine.playing, this.timeLine.getFps());
    }
    async updateMap(draw, timelineItem, params, friendLineItemId, // 关联的 timelineItemId
    isTemp) {
        timelineItem.drawId = draw.id;
        timelineItem.hidden && timelineItem?.hidden();
        const fps = this.timeLine.getFps();
        const friendLineItem = this.getLineItemIdToLineItem(friendLineItemId);
        const lodObj = this.drawIdToObj[draw.id];
        const params_ = lodObj
            ? { ...lodObj.params, ...(params || {}) }
            : params || {};
        const lineItem = [];
        if (lodObj) {
            lineItem.push(...lodObj.lineItem);
        }
        if (!lineItem.includes(timelineItem)) {
            lineItem.push(timelineItem);
        }
        if (friendLineItem) {
            timelineItem?.friendIds.push(friendLineItemId);
            friendLineItem?.friendIds.push(timelineItem.id);
        }
        this.drawIdToObj[draw.id] = {
            lineItem,
            draw: draw,
            params: params_,
            id: draw.id,
        };
        if (params.isAudio) {
            if (params.tts &&
                timelineItem.params.tts &&
                timelineItem.params.tts.url) {
                const audioUrl = await this.ttsRequest(timelineItem.params.tts.url);
                timelineItem.isTimeChange = false;
                await this.updateAudioMap(audioUrl, timelineItem, timelineItem.id, isTemp);
                // 增加半秒的时间，防止UNITY播放音频时，口唇不对应
                if (!isTemp) {
                    timelineItem.time += fps / 2;
                }
            }
            else {
                await this.updateAudioMap(params.url, timelineItem, timelineItem.id, isTemp);
            }
        }
        if (params.isVideo) {
            await this.updateVideoMap(params.url, timelineItem, timelineItem.id, isTemp);
        }
        timelineItem.show && timelineItem?.show();
    }
    addTimeLineItem(timeLineItemParams, isEvent = true) {
        const params = [...this.column, ...defindColumn].find((item) => item.type === timeLineItemParams.type);
        if (!params) {
            throw new Error("type not found");
        }
        const timeLineFps = this.timeLine.getFps();
        const timelineRow = timeLineItemParams.timelineRow || new TimelineRow();
        const parentRow = this.timeLine.rows.find(v => v.id === timeLineItemParams.parentId);
        timelineRow.bcColor = params.bcColor;
        timelineRow.icon = params.icon;
        timelineRow.activeIcon = params.activeIcon || params.icon;
        timelineRow.type = params.type;
        timelineRow.txtColor = params.txtColor;
        timelineRow.name = params.sideText; // 新增 时间轴类型 名称
        timelineRow.setParent(parentRow);
        let startTime;
        if (timeLineItemParams.startTime || timeLineItemParams.startTime == 0) {
            startTime = timeLineItemParams.startTime * timeLineFps;
        }
        else {
            startTime = this.timeLine.getCurrentTime() * timeLineFps;
        }
        const time = timeLineItemParams.time * timeLineFps;
        const timelineItem = new TimelineItem(startTime, time);
        if (timeLineItemParams.label == "" || timeLineItemParams.label) {
            timelineItem.txt = timeLineItemParams.label;
        }
        else {
            timelineItem.txt = params.label;
        }
        if ((timeLineItemParams.type === "audio" || timeLineItemParams.type === "video") && typeof timeLineItemParams.volume === 'number') {
            timelineItem.params.volume = timeLineItemParams.volume;
        }
        timelineItem.brotherId = timeLineItemParams.brotherId;
        timelineItem.type = params.type;
        timelineItem.isChangeY = !timeLineItemParams.isNChangeY;
        timelineItem.isMoveXchange = typeof timeLineItemParams.isMoveXchange === "boolean" ? timeLineItemParams.isMoveXchange : true;
        timelineItem.isShowDragTag = typeof timeLineItemParams.isShowDragTag === "boolean" ? timeLineItemParams.isShowDragTag : true;
        timelineItem.maxTime =
            timeLineItemParams.maxTime && timeLineItemParams.maxTime * timeLineFps;
        timelineItem.sideText = params.sideText; // 新增 时间轴类型 名称
        timelineItem.txtFontSize = timeLineItemParams.txtFontSize || timelineItem.txtFontSize;
        timelineRow.addItem(timelineItem);
        this.timeLine.addRow(timelineRow, isEvent);
        return { timelineRow, timelineItem };
    }
    getLineItemIdToObj(lineItemId) {
        return Object.values(this.drawIdToObj).find((obj) => obj.lineItem.some((lineItem) => lineItem.id === lineItemId));
    }
    getLineItemIdToLineItem(lineItemId) {
        if (!lineItemId)
            return null;
        return Object.values(this.drawIdToObj).reduce((map, obj) => {
            const lineItem = obj.lineItem.find((lineItem) => lineItem.id === lineItemId);
            if (lineItem) {
                return lineItem;
            }
            return map;
        }, null);
    }
    addImage(params, friendLineItemId, // 关联的 drawId
    isEvent = true) {
        params.type = params.type || "image";
        const loaded = () => {
            if (params.layerPositionType) {
                drawImage.setLayerPosition(params.layerPositionType);
            }
        };
        let drawImage;
        if (!params.positionType && params.lodDraw) {
            const lodDrawParams = this.canvasDraw.getDrawParamsToId(params.lodDraw.id);
            drawImage = this.canvasDraw.drawImageFun({
                url: params.url,
                width: lodDrawParams?.w,
                height: lodDrawParams?.h,
                center: lodDrawParams.newFramework?.center,
                angle: lodDrawParams?.angle,
                baseWH: lodDrawParams?.newFramework.baseWH,
                point: lodDrawParams?.newFramework?.point,
                frameWorkId: lodDrawParams?.newFramework?.id,
                ex: lodDrawParams?.x,
                ey: lodDrawParams?.y,
                isShow: lodDrawParams?.isShow,
                index: lodDrawParams?.index,
                layerType: lodDrawParams?.self?.layerType,
            }, isEvent, loaded);
        }
        else {
            drawImage = this.canvasDraw.drawImageFun(params, isEvent, loaded);
        }
        if (params.drawId) {
            const obj = this.drawIdToObj[params.drawId];
            const lineItem = obj.lineItem[0];
            const timelineRow = this.timeLine.rows.find((row) => row.items.includes(lineItem));
            const rowLastLineItem = timelineRow.items[timelineRow.items.length - 1];
            const lastLineItemEndTime = rowLastLineItem.startTime + rowLastLineItem.time;
            const startTime = lastLineItemEndTime / this.timeLine.getFps();
            params.startTime = startTime;
            params.timelineRow = timelineRow;
        }
        const { timelineRow, timelineItem } = this.addTimeLineItem({
            timelineRow: params.timelineRow,
            startTime: params.startTime,
            time: params.time || 5,
            type: params.type,
            label: params.label,
            brotherId: params.brotherId,
            isNChangeY: params.isNChangeY,
            isMoveXchange: params.isMoveXchange,
            isShowDragTag: params.isShowDragTag,
            txtFontSize: params.lineTxtFontSize,
        }, isEvent);
        this.updateMap(drawImage, timelineItem, params, friendLineItemId);
        return drawImage.id;
    }
    setImage(id, params) {
        const obj = this.drawIdToObj[id];
        if (!obj) {
            return;
        }
        if (params.url && obj.params.layerType === "digitalMan") {
            obj.params = { ...obj.params, ...params };
            this.canvasDraw.updatePeeling(id, params.url);
        }
        if (params.data) {
            obj.params.data = { ...(obj.params.data || {}), ...(params.data || {}) };
            if (obj.lineItem[0] && obj.lineItem[0].txt) {
                obj.lineItem[0].txt = params.data?.figureNm;
            }
        }
    }
    /**
     * 设置gif集合
     */
    setGifKeyMap(keyMap) {
        this.gifFrameDrawerMange.setKeyMap(keyMap);
        Object.values(this.drawIdToObj).forEach((obj) => {
            if (obj.params.gifType) {
                if (keyMap[obj.params?.gifType]) {
                    obj.params = { ...obj.params, ...keyMap[obj.params.gifType] };
                }
                else {
                    this.removeLineItemId(obj.lineItem[0].id);
                }
            }
        });
    }
    /**
     *  1：修改文字的属性
     *  2：可以使用此方法把普通文字修改为花字
     * @param id: string             必填   元素id
     * @param params
     * @param flowerText: 'none'     必填   花字类型  "none" | "fill" | "stroke" | "both"; 默认 none 不是花字，fill 填充花字无描边 ，stroke描边花字无填充， both即填充又描边
     * @param color?: '#000000'      非必填 花字的填充颜色 （flowerText 为  "fill" | "both" 时生效）默认： '#000000'。
     * @param strokeColor?: '#ffffff'非必填 花字的描边颜色 （flowerText 为  "stroke" | "both" 时生效）默认： '#ffffff'。
     * @param flowerFontWeight?: 1   非必填 花字的字重 ， 1、2、3、4、5、6、7、8、9、10。
     * @param fontSize?: 40          非必填 花字的字号
     * @param fontFamily?: ''        非必填 字族
     * @param isShowShadow?: ''      非必填 默认false 是否显示阴影
     * @param shadowColor?: ''       非必填 阴影颜色  默认 无
     * @param shadowOffsetX?: ''     非必填 阴影X轴偏移量 默认 2
     * @param shadowOffsetY?: ''     非必填 阴影Y轴偏移量 默认 2
     * @param shadowBlur?: ''        非必填 阴影模糊值 默认 1
     *
     */
    setTextStyle(id, params) {
        const obj = this.drawIdToObj[id];
        if (!obj) {
            return;
        }
        this.canvasDraw.changeToFlowerText({ id, ...params });
    }
    /**
     * 修改文字
     * @id string              要修改的文字id
     * @param params
     * @param text: string|Array     非必填
     * @param tts: {url:string, data:any}|{url:string, data:any}                非必填 后端返回的tts,有tts的时候会取tts时间赋值time
     * @param img: {name:string, url: string} | {name:string, url: string}[];   非必填 要展示的图片
     * @param imgPosT：string        非必填 要展示的图片的位置类型 'topLeft' | 'topCenter'| 'topRight' | 'left' | 'center'| 'right' | 'btmLeft' | 'btmCenter' | 'btmRight'
     * @param isNChangeY?: boolean   非必填 默认 false 是否可以改变Y轴
     * @param time?: number          非必填
     * @param flowerText: 'none'     必填   花字类型  "none" | "fill" | "stroke" | "both"; 默认 none 不是花字，fill 填充花字无描边 ，stroke描边花字无填充， both即填充又描边
     * @param color?: '#000000'      非必填 花字的填充颜色 （flowerText 为  "fill" | "both" 时生效）默认： '#000000'。
     * @param strokeColor?: '#ffffff'非必填 花字的描边颜色 （flowerText 为  "stroke" | "both" 时生效）默认： '#ffffff'。
     * @param flowerFontWeight?: 1   非必填 花字的字重 ， 1、2、3、4、5、6、7、8、9、10。
     * @param fontSize?: 40          非必填 花字的字号
     * @param fontFamily?: ''        非必填 字族
     * @param isShowShadow?: ''      非必填 默认false 是否显示阴影
     * @param shadowColor?: ''       非必填 阴影颜色  默认 无
     * @param shadowOffsetX?: ''     非必填 阴影X轴偏移量 默认 0
     * @param shadowOffsetY?: ''     非必填 阴影Y轴偏移量 默认 0
     * @param shadowBlur?: ''        非必填 阴影模糊值 默认 1
     */
    async setText(id, params) {
        const obj = this.drawIdToObj[id];
        if (!obj) {
            return;
        }
        if (!Array.isArray(params.text)) {
            params.text = [params.text];
        }
        if (!Array.isArray(params.tts)) {
            params.tts = [params.tts];
        }
        if (!Array.isArray(params.img)) {
            params.img = [params.img];
        }
        let { lineItem, params: lodParams, draw } = obj;
        lineItem = [...lineItem];
        const timelineRow_ = this.timeLine.rows.find((row) => row.items.includes(lineItem[0]));
        const imgTimelineItems = lineItem.map((lineItem) => this.getLineItemIdToLineItem(lineItem.friendIds[0]));
        const imgTimelineItem = this.getLineItemIdToLineItem(lineItem[0].friendIds[0]);
        const imgDraws = imgTimelineItems.map((lineItem) => this.drawIdToObj[lineItem?.drawId]?.draw);
        const imgTimelineRow_ = this.timeLine.rows.find((row) => row.items.includes(imgTimelineItem));
        // @ts-expect-error
        this.setTextStyle(id, params);
        params.startTime = lineItem[0].startTime / this.timeLine.getFps();
        params.time = lineItem[0].time / this.timeLine.getFps();
        lineItem.forEach((lineItem) => {
            lineItem.isDelete = true;
        });
        imgTimelineItems.filter(lineItem => lineItem).forEach((lineItem) => {
            lineItem.isDelete = true;
        });
        this.addText(params, {
            drawText: draw,
            textTimelineRow: timelineRow_,
            imgTimelineRow: imgTimelineRow_,
            imgDraws,
            selectIndex: lineItem.findIndex((lineItem) => lineItem.isSelected),
        }, () => {
            lineItem.forEach((lineItem) => {
                this.timeLine.removeItem([lineItem], true, false);
            });
        });
    }
    /**
     * 渲染文字
     * @param params
     * @param type: string              非必填 默认 'txt'
     * @param text: string|Array        必填
     * @param defaultPadding：number    非必填
     * @param tts: {url:string, data:any}|{url:string, data:any}                非必填 后端返回的tts,有tts的时候会取tts时间赋值time
     * @param img: {name:string, url: string} | {name:string, url: string}[];   非必填 要展示的图片
     * @param imgPosT: string           非必填 要展示的图片的位置类型 'topLeft' | 'topCenter'| 'topRight' | 'left' | 'center'| 'right' | 'btmLeft' | 'btmCenter' | 'btmRight'
     * @param textPost: string          非必填 要展示的图片的位置类型 'topLeft' | 'topCenter'| 'topRight' | 'left' | 'center'| 'right' | 'btmLeft' | 'btmCenter' | 'btmRight'
     * @param imgType: string           非必填 默认 'image' 图片类型 默认图片的默认类型
     * @param startTime: number         非必填 默认 当前时间线
     * @param isNChangeY?: boolean   非必填 默认 false 是否可以改变Y轴
     * @param time: number              非必填 默认 5
     * @param color: string             非必填  默认 #000000 字体颜色，（花字颜色也通过这个参数设置）
     * @param fontSize?: number         非必填 默认 20
     * @param fontFamily?: string       非必填 默认 'Microsoft YaHei'
     * @param textAlign?: string        非必填 默认 'start'
     * @param textBaseline?: string     非必填 默认 'middle';
     * @param fontWeight?: string       非必填 默认 'normal'  字重(非花字) 其他 'normal' | 'bold' | 'lighter'| 'bolder' | '100' ~'900';
     * @param flowerFontWeight?: number       非必填 默认 1  字重(花字)
     * @param isFlowerStrokeText?: boolean       非必填 默认 false  是否是花字;
     * @param strokeColor?: string      非必填 默认 #ffffff 描边颜色
     * @param flowerText?: string       非必填 默认 'none' 花字类型  "none" | "fill" | "stroke" | "both"; 默认 none 不是花字，fill 填充花字无描边 ，stroke描边花字无填充， both即填充又描边
     * @param shadowColor?: string      非必填 默认 无 阴影颜色
     * @param shadowOffsetX?: number    非必填 默认 2 阴影X轴偏移量
     * @param shadowOffsetY?: number    非必填 默认 2 阴影Y轴偏移量
     * @param shadowBlur?: number       非必填 默认 1 阴影模糊值
     * @param data?: any                非必填 默认 无 附加数据
     */
    addText(params, lodText, successCallback) {
        params.type = params.type || "txt";
        if (!Array.isArray(params.text)) {
            params.text = [params.text];
        }
        if (!Array.isArray(params.tts)) {
            params.tts = [params.tts];
        }
        if (!Array.isArray(params.img)) {
            params.img = [params.img];
        }
        const drawText = lodText?.drawText ||
            this.canvasDraw.drawText({
                ...params,
                text: params.text[0],
                positionType: params.textPost || 'center', // 如果是语音，位置在下面
            });
        const textTimelineRow = lodText?.textTimelineRow || new TimelineRow();
        const imgTimelineRow = lodText?.imgTimelineRow || new TimelineRow();
        const imgDraws = lodText?.imgDraws;
        const selectIndex = lodText?.selectIndex;
        const timeLineFps = this.timeLine.getFps();
        let preLineTiem = null;
        const getStartTime = (timeLineFps) => {
            if (!preLineTiem) {
                if (params.startTime || params.startTime == 0) {
                    return params.startTime;
                }
                return this.timeLine.getCurrentTime();
            }
            return (preLineTiem.time + preLineTiem.startTime) / timeLineFps;
        };
        setTimeout(async () => {
            const newItems = [];
            for (let i = 0; i < params.text.length; i++) {
                const text = params.text[i];
                const startTime = getStartTime(timeLineFps);
                const { timelineRow, timelineItem } = this.addTimeLineItem({
                    startTime,
                    timelineRow: textTimelineRow,
                    time: params.time || 5,
                    type: params.type,
                    isNChangeY: params.isNChangeY,
                    label: text,
                    isShowDragTag: params.isShowDragTag,
                    isMoveXchange: params.isMoveXchange,
                    txtFontSize: params.lineTxtFontSize,
                    // brotherId: params.brotherId,
                }, false);
                newItems.push(timelineItem);
                // 解决 tts ppt上传后 默认中tts问题
                // timelineItem.isSelected = true;
                preLineTiem = timelineItem;
                if (params.tts[i]) {
                    const audioUrl = params.tts[i].url;
                    timelineItem.params.tts = params.tts[i];
                    timelineItem.params.tts.txtWithTimes = params.tts[i].txtWithTimes?.map((item) => {
                        return {
                            time: item.time * timeLineFps,
                            char: item.char,
                        };
                    });
                    // 文字 timelineItem 嵌入数字人需要执行的动作、表情的gif图    --------    业务现在不需要了，暂时注释
                    // timelineItem.params.actList = params.tts[i].data.actList || [];
                    // timelineItem.params.emojiList = params.tts[i].data.emojiList || [];
                    // timelineItem.params.actList.forEach((act: ActOrEmo) => {
                    //   this.gifFrameDrawerMange.addGifFrameDrawer(act.gifType);
                    // });
                    // timelineItem.params.emojiList.forEach((emo: ActOrEmo) => {
                    //   this.gifFrameDrawerMange.addGifFrameDrawer(emo.gifType);
                    // });
                    await this.updateMap(drawText, timelineItem, {
                        ...params,
                        text,
                        isAudio: true,
                        audioUrl,
                    });
                }
                else {
                    await this.updateMap(drawText, timelineItem, { ...params, text });
                }
                [...params?.tts[i]?.data?.actList || [], ...params?.tts[i]?.data?.emojiList || []].forEach((act) => {
                    const startTime = timelineItem.startTime / timeLineFps + act.inTime;
                    const time = act.endTime - act.inTime;
                    this.addEmptyTimelineItem({ ...act, gifType: act.gifType, startTime, time });
                });
                if (params.img[i]) {
                    const { name, url } = params.img[i];
                    const imgParams = {
                        label: name,
                        url,
                        type: params.imgType,
                        startTime,
                        isNChangeY: params.isNChangeY,
                        time: timelineItem.time / timeLineFps,
                        timelineRow: imgTimelineRow,
                    };
                    if (params.imgPosT) {
                        imgParams.positionType = params.imgPosT;
                    }
                    else if (imgDraws && imgDraws[i]) {
                        imgParams.lodDraw = imgDraws[i];
                    }
                    await this.addImage(imgParams, timelineItem.id, false);
                }
            }
            successCallback && successCallback();
            //  杜 ： 暂时注释并 修改默认为第一个， 但是可能会影响你之前修复 tts 生成 BUG. 如果有影响问题，就再打开处理。
            this.timeLine.selectItem(newItems[selectIndex] || newItems[newItems.length - 1], true);
            setTimeout(() => {
                // this.timeLine.selectItem(newItems[0] || newItems[0], true);
                this.timeLine.selectItem(newItems[selectIndex] || newItems[newItems.length - 1], true);
                this.timeLine.emit(Timeline.EVENT_MAP.onTimelineItemChange);
                this.emit("ADD_TEXT_SUCCESS");
                this.timeLine.resize();
            }, 100);
        });
        return drawText.id;
    }
    /**
     * 插入文字
     * @param {object} params 添加字幕时的参数
     * @param {string} params.text: 必填
     * @param {string} params.drawId: 必填
     * @param {string} params.timelineRowId: 必填
     * @param {url:string, data:any} params.tts: 非必填 后端返回的tts,有tts的时候会取tts时间赋值time
     * @param {any} params.data?: 非必填 默认 无 附加数据
     */
    insertText(params) {
        const obj = this.drawIdToObj[params.drawId];
        const draw = this.canvasDraw.getDrawParamsToId(params.drawId);
        const row = this.timeLine.rows.find((row) => row.id === params.timelineRowId);
        const timeLineFps = this.timeLine.getFps();
        this.ttsRequest(params.tts.url).then((url) => {
            getMediaDuration(url, false).then((duration) => {
                const { timelineRow, timelineItem } = this.addTimeLineItem({
                    timelineRow: row,
                    time: duration,
                    type: obj.params.type,
                    label: params.text,
                    isNChangeY: params.isNChangeY,
                    isShowDragTag: params.isShowDragTag,
                    isMoveXchange: params.isMoveXchange,
                    txtFontSize: params.lineTxtFontSize,
                });
                timelineItem.params.tts = params.tts;
                timelineItem.params.tts.txtWithTimes = params.tts.txtWithTimes?.map((item) => {
                    return {
                        time: item.time * timeLineFps,
                        char: item.char,
                    };
                });
                obj.params.tts?.push(params.tts);
                // timelineItem.params.actList = params.tts.data.actList || [];
                // timelineItem.params.emojiList = params.tts.data.emojiList || [];
                // timelineItem.params.actList.forEach((act: ActOrEmo) => {
                //   this.gifFrameDrawerMange.addGifFrameDrawer(act.gifType);
                // });
                // timelineItem.params.emojiList.forEach((emo: ActOrEmo) => {
                //   this.gifFrameDrawerMange.addGifFrameDrawer(emo.gifType);
                // });
                if (params.tts.data.actList) {
                    params.tts.data.actList.forEach((act) => {
                        const startTime = timelineItem.startTime / timeLineFps + act.inTime;
                        const time = act.endTime - act.inTime;
                        this.addEmptyTimelineItem({ ...act, gifType: act.gifType, startTime, time, });
                    });
                }
                if (params.tts.data.emojiList) {
                    params.tts.data.emojiList.forEach((emo) => {
                        const startTime = timelineItem.startTime / timeLineFps + emo.inTime;
                        const time = emo.endTime - emo.inTime;
                        this.addEmptyTimelineItem({ ...emo, gifType: emo.gifType, startTime, time, });
                    });
                }
                this.updateMap(draw, timelineItem, {
                    ...obj.params,
                    text: params.text,
                    isAudio: true,
                    audioUrl: params.tts.url,
                });
                this.timeLine.selectItem(timelineItem, true);
                this.timeLine.resize();
                this.emit("ADD_TEXT_SUCCESS");
            });
        });
    }
    addAudio(params) {
        params.type = params.type || "audio";
        const drawId = uuid();
        const { timelineRow, timelineItem } = this.addTimeLineItem({
            time: params.time || params.maxTime || 5,
            startTime: params.startTime,
            type: params.type,
            label: params.label,
            brotherId: params.brotherId,
            maxTime: params.maxTime,
            volume: params.volume,
            isShowDragTag: params.isShowDragTag,
            isMoveXchange: params.isMoveXchange,
            isNChangeY: params.isNChangeY,
            txtFontSize: params.lineTxtFontSize,
        });
        this.updateMap({ id: drawId, isNoDraw: true }, timelineItem, {
            ...params,
            isAudio: true,
        });
        return drawId;
    }
    addVideo(params) {
        params.type = params.type || "video";
        const drawVideo = this.canvasDraw.drawVideoFun({
            ...params,
            parentId: this.canvasDraw.parentId,
            volume: params.volume,
        });
        const { timelineRow, timelineItem } = this.addTimeLineItem({
            time: params.time || params.maxTime || 5,
            type: params.type,
            startTime: params.startTime,
            label: params.label,
            brotherId: params.brotherId,
            maxTime: params.maxTime,
            volume: params.volume,
            isShowDragTag: params.isShowDragTag,
            isMoveXchange: params.isMoveXchange,
            isNChangeY: params.isNChangeY,
            txtFontSize: params.lineTxtFontSize,
        });
        this.updateMap(drawVideo, timelineItem, { ...params, isVideo: true });
        return drawVideo.id;
    }
    addGif(params) {
        params.type = params.type || "gif";
        const drawGif = this.canvasDraw.drawGifImage({
            ...params,
            firstUrl: params.gifUrls[0],
            currentUrl: params.gifUrls[0],
            urls: params.gifUrls,
        });
        const { timelineRow, timelineItem } = this.addTimeLineItem({
            time: params.time || 5,
            startTime: params.startTime,
            type: params.type,
            label: params.label,
            brotherId: params.brotherId,
            isShowDragTag: params.isShowDragTag,
            isMoveXchange: params.isMoveXchange,
            txtFontSize: params.lineTxtFontSize,
        });
        this.updateMap(drawGif, timelineItem, params);
        return drawGif.id;
    }
    setGif(id, params) {
        const obj = this.drawIdToObj[id];
        if (!obj) {
            return;
        }
        obj.params.data = { ...(obj.params.data || {}), ...(params.data || {}) };
    }
    setBackGround(src, params = {}) {
        params.type = params.type || "background";
        this.canvasDraw.setCanvasBackground(src);
        const id = uuid();
        this.updateMap({ id, src, isNoDraw: true, show() { }, hidden() { } }, {}, params);
        const tem = this.export();
        this.handleInsertTem(tem);
        return id;
    }
    clearBackGround() {
        this.canvasDraw.clearCanvasBackground();
    }
    addEmptyTimelineItem(params) {
        params.type = params.type || "empty";
        const timelineRow_ = this.timeLine.rows.find(row => row.id == params.timelineRowId);
        const { timelineRow, timelineItem } = this.addTimeLineItem({
            startTime: params.startTime,
            timelineRow: timelineRow_,
            time: params.time || 5,
            type: params.type,
            label: params.label,
            parentId: params.parentId,
            brotherId: params.brotherId,
            isNChangeY: params.isNChangeY,
            txtFontSize: params.lineTxtFontSize,
        });
        timelineItem.durationModifiable = false;
        const id = uuid();
        if (params.gifType) {
            if (params.data.actId) {
                timelineItem.params.actList = [
                    {
                        gifType: params.gifType,
                        actId: params.data.actId,
                        inTime: 0,
                        endTime: timelineItem.time,
                    }
                ];
                this.gifFrameDrawerMange.addGifFrameDrawer(params.gifType);
            }
        }
        this.updateMap({ id, type: "none", gifType: params.gifType }, timelineItem, params);
        return id;
    }
    async updateVideoMap(videoUrl, lineItem, id, isTemp = false) {
        urlToMedium[videoUrl] = this.drawIdToObj[lineItem.drawId].draw.video;
        lineItemIdToMedium[id] = urlToMedium[videoUrl];
        if (!isTemp) {
            const duration = await getMediaDuration(videoUrl, true);
            lineItem.maxTime = duration * this.timeLine.getFps();
            lineItem.time = lineItem.maxTime;
        }
    }
    async updateAudioMap(audioUrl, lineItem, id, isTemp = false) {
        urlToMedium[audioUrl] = new Audio(audioUrl);
        lineItemIdToMedium[id] = urlToMedium[audioUrl];
        if (!isTemp) {
            const duration = await getMediaDuration(audioUrl, false);
            lineItem.maxTime = duration * this.timeLine.getFps();
            lineItem.time = lineItem.maxTime;
        }
    }
    getThumbnail() {
        this.updateCurrentTime(0);
        this.canvasDraw.drawCanvas();
        const base64Img = this.canvasDraw.canvas.toDataURL();
        // const img = document.createElement("img");
        // img.src = base64Img;
        // document.body.appendChild(img);
        this.updateCurrentTime(this.timeLine.getCurrentTime() * this.timeLine.getFps());
        this.canvasDraw.drawCanvas();
        return base64Img;
    }
    play() {
        this.timeLine.play();
    }
    pause() {
        this.timeLine.pause();
    }
    // 缩小 时间轴精度
    timeLineScaleZoomOut() {
        this.timeLine.zoomOut();
    }
    // 放大 时间轴精度
    timeLineScaleZoomIn() {
        this.timeLine.zoomIn();
    }
    // 检测时间线A的所有时间项是否包含时间线B的所有时间项
    checkTimeLineContain(aType, bType) {
        const aLineItems = this.timeLine.rows.reduce((lineItems, row) => {
            return lineItems.concat(row.items.filter(item => this.drawIdToObj[item.drawId]?.params?.type === aType));
        }, []);
        const bLineItems = this.timeLine.rows.reduce((lineItems, row) => {
            return lineItems.concat(row.items.filter(item => this.drawIdToObj[item.drawId]?.params?.type === bType));
        }, []);
        return bLineItems.every(bLineItem => {
            return aLineItems.some(aLineItem => {
                return aLineItem.startTime <= bLineItem.startTime && aLineItem.startTime + aLineItem.time >= bLineItem.startTime + bLineItem.time;
            });
        });
    }
    // 检测单type时间线是否有重合
    checkTimeLineOverlap(aType, bType) {
        bType = bType || aType; // 获取 aType 类型的所有时间线项目
        const aLineItems = this.timeLine.rows.reduce((lineItems, row) => {
            return lineItems.concat(row.items.filter(item => this.drawIdToObj[item.drawId]?.params?.type === aType));
        }, []);
        // 获取 bType 类型的所有时间线项目
        const bLineItems = this.timeLine.rows.reduce((lineItems, row) => {
            return lineItems.concat(row.items.filter(item => this.drawIdToObj[item.drawId]?.params?.type === bType));
        }, []);
        if (aType === bType) {
            // 检查 aType 类型的时间线项目是否有相交
            for (let i = 0; i < aLineItems.length; i++) {
                for (let j = i + 1; j < aLineItems.length; j++) {
                    if (aLineItems[i].startTime < aLineItems[j].startTime + aLineItems[j].time &&
                        aLineItems[i].startTime + aLineItems[i].time > aLineItems[j].startTime) {
                        return true;
                    }
                }
            }
        }
        else {
            // 检查 aType 和 bType 类型的时间线项目是否有相交
            for (const aLineItem of aLineItems) {
                for (const bLineItem of bLineItems) {
                    if (aLineItem.startTime < bLineItem.startTime + bLineItem.time &&
                        aLineItem.startTime + aLineItem.time > bLineItem.startTime) {
                        return true;
                    }
                }
            }
        }
        // 如果没有找到相交，返回 false
        return false;
    }
    startAnimation() {
        this.timeLine.startAnimation();
        this.canvasDraw.startAnimation();
    }
    stopAnimation() {
        this.timeLine.stopAnimation();
        this.canvasDraw.stopAnimation();
    }
    clear(isEvene = true) {
        this.canvasDraw.clearCanvasAndResertDefaultValues();
        this.timeLine.clear(isEvene);
        this.drawIdToObj = {};
        Object.values(urlToMedium).forEach((audio) => audio.pause());
    }
    import(json, isAddHistory = true) {
        if (!json)
            return;
        const exprotData = JSON.parse(json);
        this.drawIdToObj = {};
        this.timeLine.import(json, false);
        this.canvasDraw.drawTemplate(json);
        this.gifFrameDrawerMange.setKeyMap(exprotData.gifKeyMap);
        const drawMap = this.canvasDraw.drawList.reduce((drawMap, v) => {
            drawMap[v.id] = v.self;
            return drawMap;
        }, {});
        const timeLineItemMap = this.timeLine.rows.reduce((timeLineItemMap, row) => {
            row.items.forEach((item) => {
                timeLineItemMap[item.id] = item;
            });
            return timeLineItemMap;
        }, {});
        exprotData.data.forEach((dataItem) => {
            const draw = drawMap[dataItem.draw.id] || dataItem.draw;
            const timeLineItem = timeLineItemMap[dataItem.lineItem.id] || dataItem.lineItem;
            (timeLineItem.params?.actList || []).forEach((act) => {
                this.gifFrameDrawerMange.addGifFrameDrawer(act.gifType || act.gifUrl);
            });
            (timeLineItem.params?.emojiList || []).forEach((emo) => {
                this.gifFrameDrawerMange.addGifFrameDrawer(emo.gifType || emo.gifUrl);
            });
            this.updateMap(draw, timeLineItem, dataItem.params, undefined, true);
        }, {});
        this.updateCurrentTime(0);
        this.emit("IMPORT_COMPLETED_EVENT", this.export());
        if (isAddHistory) {
            // const params = exprotData.data?
            // this.history.insert(json);
            this.handleInsertTem(exprotData);
        }
    }
    export() {
        const drawData = this.canvasDraw.getDrawItem();
        const data = Object.keys(this.drawIdToObj).reduce((data, key) => {
            const timeLineFps = this.timeLine.getFps();
            const { lineItem, params, draw } = this.drawIdToObj[key];
            lineItem.forEach((lineItem) => {
                const newDraw = drawData.find((item) => item.id === key) || draw;
                data.push({
                    type: params.type,
                    time: lineItem.time / timeLineFps,
                    startTime: lineItem.startTime / timeLineFps,
                    endTime: (lineItem.startTime + lineItem.time) / timeLineFps,
                    params: { ...params },
                    lineItem,
                    draw: newDraw,
                });
            });
            return data;
        }, []);
        const canvas = this.canvasDraw.canvas;
        const totalTime = this.timeLine.getTotalTime();
        const exportData = {
            data,
            gifKeyMap: this.gifFrameDrawerMange.getKeyMap(),
            width: canvas.width,
            height: canvas.height,
            canvasType: this.canvasDraw.canvasType,
            bcImgUrl: this.canvasDraw.bcImgUrl,
            totalTime,
            isShowCaption: this.canvasDraw.isShowCaption,
        };
        return JSON.parse(JSON.stringify(exportData, (key, value) => {
            if (["brothers", "row", "parent", 'children', 'timeLineRow'].includes(key)) {
                return;
            }
            return value;
        }));
    }
}
