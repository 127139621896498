import EventEmitter from "../../Utils/EventEmitter";
import { debounce, getDocPosition, 
// isPointInRect,
rotateToDegree,
// setCoordinate,
 } from "../utils/common";
import { Border } from "./framework";
import { BCIDraw } from "./bciDraw";
import { GifDraw } from "./gifDraw";
import { ImageDraw } from "./imageDraw";
import { PPTDraw } from "./pptDraw";
import { RightClickActionBox } from "./rightClickActionBox";
import { TextDraw } from "./textDraw";
import { VideoDraw } from "./videoDraw";
export default class CanvasDraw extends EventEmitter {
    id;
    animationId;
    parentId;
    canvas;
    drawList;
    // index: number;
    ctx = null;
    initImageData;
    target; //TargetInfoType | undefined;
    rightActionBox;
    rightActionBoxWH = [161, 250];
    canvasType;
    center;
    bcImgUrl;
    width;
    height;
    resolution = [
        [1920, 1080],
        [1080, 1920],
    ];
    resolutionType = '1080P';
    drawTem = false;
    BCIDraw = new BCIDraw();
    border = new Border(this);
    current = null;
    lineItem = undefined;
    isShowCaption = true;
    options;
    fps = 40; // 锁帧 60帧
    lastTime = 0; // 锁帧计算上次帧数时间
    // 撤回和恢复，节流
    debounceListenerDRAW_CHANGE_EVEN = null;
    constructor(options) {
        super();
        this.id = options.id;
        this.parentId = options.parentId;
        this.canvas = document.createElement("canvas"); // 预渲染canvas
        // this.drawList = [];
        this.initImageData = undefined;
        this.target = undefined;
        // this.currentTaget = undefined;
        // this.index = 0;
        this.canvasType = options.canvasType || "16:9";
        this.center = [];
        this.options = options;
        this.canvas = null; // 画布
        this.BCIDraw = new BCIDraw();
        this.drawList = []; // 画布的层
        this.debounceListenerDRAW_CHANGE_EVEN = debounce(() => {
            this.emit("DRAW_CHANGE_EVEN");
        }, 500, false);
        // this.target = document.getElementById(options.id);
        this.startAnimation();
    }
    initEvent() {
        document.addEventListener("mousemove", this.handleMouseMove.bind(this));
        this.canvas.addEventListener("mousedown", (e) => {
            let p_x = e.pageX;
            let p_y = e.pageY;
            const position = getDocPosition(this.canvas);
            const scale = this.width / this.canvas.offsetWidth;
            const point = [(p_x - position.x) * scale, (p_y - position.y) * scale];
            const status = this.selectLayer(point);
            if (status) {
                // 当不展示 图层时，不可以操作图层
                if (!this.current?.isShow) {
                    return;
                }
                this.selectedTarget(this.current);
                const move = (event) => {
                    const m_x = event.pageX;
                    const m_y = event.pageY;
                    const vector = [(m_x - p_x) * scale, (m_y - p_y) * scale];
                    if (status === 1) {
                        this.current.newFramework.translate(vector);
                        this.debounceListenerDRAW_CHANGE_EVEN();
                        this.current.self.positionType = "none";
                    }
                    else if (status === "r_point") {
                        const e_point = [
                            (m_x - position.x) * scale,
                            (m_y - position.y) * scale,
                        ];
                        const angle = this.getAngle(this.current.newFramework.center, this.border.r_point, e_point);
                        const la_angle = 360 * Math.PI / 180 - angle;
                        // const ra =  0 * Math.PI / 180 - angle
                        if (!isNaN(angle)) {
                            this.current.newFramework.rotate(la_angle);
                            this.debounceListenerDRAW_CHANGE_EVEN();
                        }
                        else {
                            return;
                        }
                    }
                    else {
                        this.debounceListenerDRAW_CHANGE_EVEN();
                        this.current.newFramework.zoom(status, vector);
                        // 按照比例 缩放文字的字号
                        this.current.type === "text" && this.current?.self?.setFontSizeByScale();
                    }
                    // this.draw();
                    p_x = m_x;
                    p_y = m_y;
                    this.canvas.addEventListener('mouseout', () => this.mouseoutOfCanvas(() => {
                        this.canvas.removeEventListener("mousemove", move);
                    }));
                };
                this.canvas.addEventListener("mousemove", move);
                this.canvas.addEventListener("mouseup", () => {
                    this.canvas.removeEventListener("mousemove", move);
                    this.canvas.removeEventListener('mouseout', () => this.mouseoutOfCanvas(() => {
                        this.canvas.removeEventListener("mousemove", move);
                    }));
                    // 在鼠标抬起之后，发布修改后的数据
                    this.getLayerChangeAttributeCallBack();
                });
            }
            else {
                this.rightActionBox?.hideRightActionBox();
                this.getLayerChangeAttributeCallBack();
                this.emit("NO-CLICK-LAYER");
            }
        });
    }
    // 旋转角度
    getAngle(cen, first, second) {
        const f_c_x = first[0] - cen[0];
        const f_c_y = cen[1] - first[1];
        const s_c_x = second[0] - cen[0];
        const s_c_y = cen[1] - second[1];
        const c = Math.sqrt(f_c_x * f_c_x + f_c_y * f_c_y) *
            Math.sqrt(s_c_x * s_c_x + s_c_y * s_c_y);
        if (c === 0)
            return -1;
        const angle = Math.acos((f_c_x * s_c_x + f_c_y * s_c_y) / c);
        // 第一象限
        if (cen[0] - second[0] < 0 && cen[1] - second[1] < 0) {
            return angle;
            // 第二象限
        }
        else if (cen[0] - second[0] < 0 && cen[1] - second[1] > 0) {
            return angle;
            // 第三象限
        }
        else if (cen[0] - second[0] > 0 && cen[1] - second[1] < 0) {
            return 2 * Math.PI - angle;
            // 第四象限
        }
        else if (cen[0] - second[0] > 0 && cen[1] - second[1] > 0) {
            return 2 * Math.PI - angle;
        }
        else {
            return angle;
        }
    }
    mouseoutOfCanvas(removeMoveListion) {
        removeMoveListion && removeMoveListion();
    }
    selectLayer(point) {
        this.drawTem = false;
        if (this.current) {
            const status = this.border.isPointInSkeletion(point);
            if (status) {
                return status;
            }
            // 如果点击的图层不是上一个图层，隐藏下右键盒子
            // this.hideRightActionBox();
            this.rightActionBox?.hideRightActionBox();
        }
        const drawList = [].concat(this.drawList).reverse();
        this.current = null;
        let c_index = 0;
        drawList.forEach((item, index) => {
            if (typeof item !== "function" &&
                !this.current &&
                item.self?.newFramework?.isPointInRect(point) &&
                item?.isShow // 当不展示 图层时，不可以操作图层
            ) {
                this.current = item;
                c_index = index + 1;
            }
        });
        if (this.current && this.current?.isShow) {
            // // 当不展示 图层时，不可以操作图层
            this.chooseItem(this.drawList.length - c_index);
            return 1;
        }
        else {
            // console.log('不选择图层', this.current, this.target)
        }
        return 0;
    }
    executeDrawFun(drawId, funName, ...args) {
        this.drawList.forEach((item) => {
            if (item.id === drawId && item.self[funName]) {
                item.self[funName](...args);
            }
        });
    }
    // 鼠标样式
    handleMouseMove(event) {
        if (this.current) {
            let p_x = event.pageX;
            let p_y = event.pageY;
            const position = getDocPosition(this.canvas);
            const scale = this.width / this.canvas.offsetWidth;
            const point = [(p_x - position.x) * scale, (p_y - position.y) * scale];
            const status = this.border.isPointInSkeletion(point);
            const fPoint = this.current.self.newFramework.point || [];
            switch (status) {
                case 'point_1':
                case 'point_3':
                    this.canvas.style.cursor = "nwse-resize";
                    break;
                case 'point_2':
                case 'point_4':
                    this.canvas.style.cursor = "nesw-resize";
                    break;
                case 'c_point_1':
                case 'c_point_3':
                    this.canvas.style.cursor = " ew-resize";
                    break;
                case 'c_point_2':
                case 'c_point_4':
                    this.canvas.style.cursor = "ns-resize";
                    break;
                default:
                    const [x, y] = point;
                    if (x > fPoint[0][0] && x < fPoint[1][0] && y > fPoint[0][1] && y < fPoint[2][1]) {
                        this.canvas.style.cursor = "move";
                    }
                    else {
                        this.canvas.style.cursor = "default";
                    }
                    break;
            }
        }
    }
    draw() {
        if ((this.current?.self?.textType === 'caption-ppt' || this.current?.self?.textType === 'caption') && !this.isShowCaption) {
            return;
        }
        if (this.current && this.current?.isShow) {
            // const hiddenShowR_point = this.current.type === 'image' && this.current.layerType === "digitalMan";
            // const hiddenShowC_point = this.current.type === 'image' && this.current.layerType === "digitalMan";
            // if (this.current && this.current?.isShow) {   // 不选然骨架但是展位还在。如果图层有变化，可能会出现选不中问题
            this.border.refresh(this.current.newFramework);
        }
    }
    chooseItem(index) {
        this.current = this.drawList[index];
        // this.draw();
    }
    textOptions = {
        // eslint-disable-next-line
        textBaseline: "middle",
        // eslint-disable-next-line
        textAlign: "center",
        bgColor: "#111827",
    };
    /** @type {HTMLCanvasElement} */
    // 页面加载完成后执行 当前方法
    initContent() {
        const canvas = document.getElementById(this.id);
        canvas.style.backgroundColor = "#fff";
        this.canvas = canvas;
        this.ctx = this.canvas.getContext("2d");
        const rect = this.ctx.canvas.getBoundingClientRect();
        this.canvas.style.backgroundPositionX = "left";
        this.canvas.style.backgroundPositionY = "top";
        if (this.ctx) {
            this.resize();
            this.initImageData = this.ctx.getImageData(0, 0, canvas.width, canvas.height);
            this.ctx.font = this.getFont();
            this.ctx.textBaseline = this.textOptions.textBaseline;
            this.ctx.textAlign = this.textOptions.textAlign;
            // this.rightActionBox = this.renderRightActionBox();
            this.rightActionBox = new RightClickActionBox({
                parentId: this.parentId,
                parent: this,
                target: this.target,
                drawList: this.drawList,
                rightActionBoxWH: this.rightActionBoxWH,
            });
            this.initEvent();
            // this.bindFun();
            this.canvas.addEventListener("contextmenu", this.rightClick);
            window.onresize = () => {
                this.resize();
            };
        }
    }
    setCanvasBoxType(type) {
        this.canvasType = type;
        this.resize();
    }
    // 更改 视频的 音量 0 - 1; (0.5 = 50%)
    changeVideoEleVolume = (volume) => {
        if (this.current?.type === 'video') {
            this.current?.self?.changeVideoEleVolume(volume);
        }
    };
    // 修改分辨率
    changeResolutionType(type) {
        this.resolutionType = type;
        switch (type) {
            case "2K":
                this.resolution = [
                    [2560, 1440],
                    [1440, 2560],
                ];
                break;
            case "1080P":
                this.resolution = [
                    [1920, 1080],
                    [1080, 1920],
                ];
                break;
            case "4K":
                this.resolution = [
                    [4096, 2160],
                    [2160, 4096],
                ];
                break;
            default:
                this.resolution = [
                    [1280, 720],
                    [720, 1280],
                ];
                break;
        }
        this.resize();
    }
    /**
     * 更新当前图片的位置信息
     * positionType: 'topLeft' | 'topCenter'| 'topRight' | 'left' | 'center'| 'right' | 'btmLeft' | 'btmCenter' | 'btmRight'；
     * defaultPadding: 默认 0  距离边框的padding
     */
    changePositonByPositionType(positionType, defaultPadding) {
        if (this.target?.self.changePositonByPositionType) {
            this.target?.self?.changePositonByPositionType(positionType, defaultPadding);
        }
    }
    /**
     * 更新当前选中图层 居中 | 半屏 | 全屏
     * layerPositionType: 'none' | 'halfScreen' |'fullScreen'| 'center'；
     */
    setLayerPosition = (layerPositionType) => {
        if (this.current && this.current.type !== 'text') {
            // if(this.current.type === 'image' && this.current.self.layerType === 'digitalMan') {
            //   return
            // }
            this.current.self.setLayerPosition(layerPositionType);
        }
    };
    // 重置canvas 布局
    resize() {
        if (this.canvasType === "16:9") {
            // if (this.canvas.width === 1280) {
            //   return;
            // }
            this.canvas.width = this.resolution[0][0];
            this.canvas.height = this.resolution[0][1];
            //宽 >1900px
            // this.canvas.style.width = '100%';
            // this.canvas.style.height = 'auto';
            this.canvas.style.backgroundSize = "100% 100%";
        }
        else {
            // if (this.canvas.width === 720) {
            //   return;
            // }
            this.canvas.width = this.resolution[1][0];
            this.canvas.height = this.resolution[1][1];
            this.canvas.style.backgroundSize = "cover";
            // gao > 1250px
            // this.canvas.style.width = 'auto';
            // this.canvas.style.height = '100%';
        }
        this.width = this.canvas.width;
        this.height = this.canvas.height;
        this.getCanvasCenter();
        // return;
        // const rect = document.getElementById(this.parentId).getBoundingClientRect();
        // const { width: rw, height: rh } = rect;
        // // 正方形
        // if (rw === rh) {
        //   if (this.canvasType === "16:9") {
        //     this.canvas.width = rw;
        //     this.canvas.height = (rw * 9) / 16;
        //   } else {
        //     this.canvas.width = (rh * 9) / 16;
        //     this.canvas.height = rh;
        //   }
        // }
        // // 宽 > 高
        // if (rw > rh) {
        //   if (this.canvasType === "16:9") {
        //     // 如果高度和宽度很接近，基于高度计算， 得出16：9 比例的宽度，超过了rw的宽
        //     const w = (16 * rh) / 9;
        //     if (w > rw) {
        //       this.canvas.width = rw;
        //       this.canvas.height = (9 * rw) / 16;
        //     } else {
        //       this.canvas.width = w;
        //       this.canvas.height = rh;
        //     }
        //   } else {
        //     this.canvas.width = (rh * 9) / 16;
        //     this.canvas.height = rh;
        //   }
        // }
        // // 高 > 宽
        // if (rh > rw) {
        //   if (this.canvasType === "16:9") {
        //     this.canvas.width = rw;
        //     this.canvas.height = (rw * 9) / 16;
        //   } else {
        //     // 高度太高，计算出的 9：16比例的的宽度，超过了父级的宽度，则默认最大父级宽度
        //     const w = (rh * 9) / 16;
        //     if (w > rw) {
        //       this.canvas.width = rw;
        //       this.canvas.height = (16 * rw) / 9;
        //     } else {
        //       this.canvas.width = w;
        //       this.canvas.height = rh;
        //     }
        //   }
        // }
        // this.width = this.canvas.width;
        // this.height = this.canvas.height;
        // this.getCanvasCenter();
    }
    //获取canvas 的中心
    getCanvasCenter() {
        this.center = [this.canvas.width / 2, this.canvas.height / 2];
    }
    // 根据图层的宽高获取，设置图层居中
    setLayerToCanvasCenter = (width, height) => {
        if (width > this.canvas.width || height > this.canvas.height) {
            return this.center;
        }
        if (this.center[0]) {
            const x = this.center[0] - width / 2;
            const y = this.center[1] - height / 2;
            return [x, y];
        }
    };
    getFont(size = 14) {
        return `${size}px -apple-system, ".SFNSText-Regular", "SF UI Text", "PingFang SC", "Hiragino Sans GB", "Helvetica Neue", "WenQuanYi Zen Hei", "Microsoft YaHei", Arial, sans-serif`;
    }
    /**
     * 设置背景图片
     * @param url     必填 图片url
     */
    setCanvasBackground = (url) => {
        this.bcImgUrl = url;
        this.BCIDraw.setBCImgUrl(url);
        // this.resize();
        // }, 100);
        // }
    };
    /**
     * 清除背景图片
     */
    clearCanvasBackground = () => {
        this.bcImgUrl = undefined;
        this.BCIDraw.clearBCImg();
    };
    /**
     * 插入图片
     * @param url: string       必填 图片url
     * @param width：number     选填 如果需要限制宽高，可填入
     * @param height：number    选填 如果需要限制宽高，可填入
     * @param layerType: 'digitalMan' | 'normal'   选填 图层的类型。如果是数字人必填'digitalMan'; digitalMan：数字人 normal普通类型图片,默认 normal
     */
    drawImageFun(params, isSelected = true, callback) {
        const imageDraw = new ImageDraw({
            ...params,
            parent: this,
        });
        imageDraw.drawImage(this.ctx, (itemInfoType) => {
            this.drawImageOrGifCallBack(itemInfoType, isSelected);
            callback && callback(imageDraw);
        });
        // this.target = imageDraw;
        return imageDraw;
    }
    /**
     * 更新 Layer XYAngle
     * updateLayerXYAngle
     *  @param x: number  x坐标
     *  @param y: number  x坐标
     *  @param angle: number  旋转角度 0 - 360
     *  @param width?: number  宽 图片类型可传， 文字不需要传
     *  @param height?: number  高 图片类型可传， 文字不需要传
     *  @params opacity?: number 0-1 非必填 图片类型可传， 文字不需要传
     */
    updateLayerXYAngle(params) {
        const self = this.current?.self;
        if (self?.updateXYAngle) {
            const { x, y, angle, opacity, width, height } = params;
            this.current?.self.updateXYAngle({
                x: Number(x),
                y: Number(y),
                width: typeof width === "number" ? Number(width) : self.newFramework.width,
                height: typeof height === "number" ? Number(height) : self.newFramework.height,
                angle: Number(angle),
                opacity: typeof opacity === "number" ? Number(opacity) : self.newFramework.opacity,
            });
            this.debounceListenerDRAW_CHANGE_EVEN();
        }
    }
    /**
     * getLayerChangeAttributeCallBack
     * 监听编辑器修改 位置，旋转，文字修改等属性的.并通过时间广播出去
     */
    getLayerChangeAttributeCallBack() {
        if (this.target?.newFramework?.point) {
            this.target.rotate = Math.round(rotateToDegree(this.target?.newFramework?.angle));
            const params = {
                current: this.current,
                x: Math.round(this.target?.newFramework?.point[0][0]),
                y: Math.round(this.target?.newFramework?.point[0][1]),
                rotate: Math.round(rotateToDegree(this.target?.newFramework?.angle)),
                target: this.target,
                lineItem: this.lineItem
            };
            this.emit("Layer-Change-Attribute", params);
        }
        else {
            // console.log("未选中图层", this.current, this.target);
        }
    }
    /**
     * 插入 PPT
     * @param url: string       必填 图片url
     * @param width：number     选填 如果需要限制宽高，可填入
     * @param height：number    选填 如果需要限制宽高，可填入
     *
     */
    drawPPT(params) {
        const pptDraw = new PPTDraw({
            ...params,
            parent: this,
        });
        pptDraw.drawPPTImage(this.ctx, this.drawImageOrGifCallBack);
        return pptDraw;
    }
    /**
     * 换肤
     * url: 带有选择的衣服或者头像的 数字人形象。更新现有的数字人形象。
     */
    updatePeeling = (id, url) => {
        const targetItem = this.drawList.filter(w => w.id === id)[0];
        if (targetItem &&
            targetItem.type == "image" &&
            targetItem.self?.layerType === "digitalMan" &&
            targetItem.self?.updatePeeling) {
            targetItem.self?.updatePeeling(url, this.updatePeelingCallback);
            targetItem.url = url;
        }
    };
    updatePeelingCallback = (targat) => {
        this.drawList.forEach((x) => {
            if (x.id === targat.id) {
                x.ele = targat.ele;
            }
        });
    };
    /** 是否显示或隐藏图层
     * showOrHideLayer
     * @param params:{id: string, isShow: boolean, timeing: 'in' | 'start'|'end'}
     */
    showOrHideLayer(params) {
        const { id, isShow } = params;
        this.drawList.forEach((w) => {
            if (w.id === id) {
                if (w.type === "text") {
                    // 暂时删除字幕
                    // const isShowCaption = w.self.textType !== 'txt'? false:isShow
                    w.isShow = isShow;
                    w.self.setIsShow(isShow);
                    return;
                }
                w.isShow = isShow;
                w.self.isShow = isShow;
            }
        });
    }
    /**
     * 清楚数据，画布，部分数据 设置为 underfind
     */
    clearCanvasAndResertDefaultValues() {
        this.ctx?.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.drawList = [];
        this.target = undefined;
        this.current = undefined;
        this.canvas.style.backgroundColor = "#ffffff";
        this.canvas.style.backgroundImage = "none";
        this.setCanvasBackground(undefined);
    }
    /**
     * 编辑 模板
     */
    drawTemplate(json) {
        this.drawTem = true;
        const exportData = typeof json === "string" ? JSON.parse(json) : json;
        if (exportData && exportData.data) {
            this.clearCanvasAndResertDefaultValues();
        }
        (exportData.data || []).forEach((w, i) => {
            const m = w.draw;
            if (this.drawList.filter((x) => x.id === m.id).length)
                return;
            // if (i === x?.index) {
            const x = m;
            if (x?.type === "image") {
                this.drawImageFun({
                    id: x?.id,
                    url: x?.url,
                    width: x?.w,
                    height: x?.h,
                    center: x.newFramework?.center,
                    angle: x?.angle,
                    baseWH: x?.newFramework.baseWH,
                    point: x?.newFramework?.point,
                    frameWorkId: x?.newFramework?.id,
                    ex: x?.x,
                    ey: x?.y,
                    isShow: x?.isShow,
                    index: x?.index,
                    layerType: x?.self?.layerType,
                    opacity: x?.self?.opacity,
                    // center: x?.
                });
            }
            if (x?.type === "gif") {
                const x = m;
                this.drawGifImage({
                    firstUrl: x.firstUrl,
                    urls: x?.urls,
                    playType: x?.playType,
                    width: x?.w,
                    height: x?.h,
                    center: x.newFramework?.center,
                    angle: x?.newFramework?.angle,
                    baseWH: x?.newFramework.baseWH,
                    point: x?.newFramework?.point,
                    frameWorkId: x?.newFramework?.id,
                    ex: x?.x,
                    ey: x?.y,
                    isShow: x?.isShow,
                    index: x?.index,
                    opacity: x?.self?.opacity,
                });
            }
            if (x?.type === "video") {
                const x = m;
                this.drawVideoFun({
                    url: x?.url,
                    id: x?.id,
                    width: x?.w,
                    height: x?.h,
                    center: x.newFramework?.center,
                    angle: x?.newFramework?.angle,
                    baseWH: x?.newFramework.baseWH,
                    point: x?.newFramework?.point,
                    frameWorkId: x?.newFramework?.id,
                    ex: x?.x,
                    ey: x?.y,
                    isShow: x?.isShow,
                    index: x?.index,
                    opacity: x?.self?.opacity,
                });
            }
            if (x?.type === "text") {
                const x = m;
                const self = x.self;
                const text = this.drawText({
                    ...x.self,
                    ...x,
                    id: x?.id,
                    deawText: self.deawText,
                    center: x.newFramework?.center,
                    angle: x?.newFramework?.angle,
                    baseWH: x?.newFramework.baseWH,
                    point: x?.newFramework?.point,
                    frameWorkId: x?.newFramework?.id,
                    isShowShadow: x?.isShow,
                    index: x?.index,
                    isShow: x?.isShow,
                    textType: self?.textType === "caption-ppt" ? self?.textType : w?.type,
                    // 暂时删除字幕
                    // textType: self?.textType === "caption-ppt" || self?.textType === 'caption' ? self?.textType : w?.type,
                    isCreate: false,
                });
                text?.changeToFlowerText({
                    flowerText: self?.flowerText,
                    isShow: self?.isShow,
                    fontWeight: self?.fontWeight,
                    color: self?.fillStyle16,
                    flowerFontWeight: self?.flowerFontWeight,
                    strokeColor: self?.strokeStyle16,
                    fontSize: typeof self?.fontSize === "number"
                        ? Math.floor(self?.fontSize)
                        : self?.fontSize,
                    fontFamily: self?.fontFamily,
                    shadowColor: self?.shadowColor16,
                    shadowOffsetX: self?.shadowOffsetX,
                    shadowOffsetY: self?.shadowOffsetY,
                    shadowBlur: self?.shadowBlur,
                    isShowShadow: self?.isShowShadow,
                    fillOpacity: self?.fillOpacity,
                    strokeOpcity: self?.strokeOpcity,
                    shadowOpcity: self?.shadowOpcity,
                    deawText: self?.deawText,
                    params: self?.params,
                });
            }
            if (x?.type === "ppt") {
                this.drawPPT({
                    id: x?.id,
                    url: x?.url,
                    width: x?.w,
                    height: x?.h,
                    center: x.newFramework?.center,
                    angle: x?.angle,
                    baseWH: x?.newFramework.baseWH,
                    point: x?.newFramework?.point,
                    frameWorkId: x?.newFramework?.id,
                    ex: x?.x,
                    ey: x?.y,
                    isShow: x?.isShow,
                    index: x?.index,
                    opacity: x?.self?.opacity,
                });
            }
            // 设置背景图片
            if (exportData?.bcImgUrl) {
                this.setCanvasBackground(exportData?.bcImgUrl);
            }
            else {
                this.canvas.style.backgroundColor = "#fffff";
                this.canvas.style.backgroundImage = "none";
                this.bcImgUrl = undefined;
            }
            // 设置横竖屏
            if (exportData?.canvasType) {
                if (exportData?.canvasType !== this.canvasType) {
                    this.setCanvasBoxType(exportData?.canvasType);
                }
            }
            // }
        });
    }
    /**
     *    创建 video
     * @param url ：string        必填 video url 路径
     * @param parentId：string     非必填 当前默认值为 canvas 的父级 ID。
     */
    drawVideoFun(params) {
        // const posXY = this.setLayerToCanvasCenter(params.width, params.height);
        const videoDraw = new VideoDraw({
            ...params,
            parentId: this.parentId,
            ctx: this.ctx,
            // x: posXY[0],
            // y: posXY[1],
            parent: this,
        });
        videoDraw.createVideo(this.drawImageOrGifCallBack);
        videoDraw.intVideo(this.ctx);
        return videoDraw;
    }
    /**
     *  渲染 GIF
     * @param firstUrl:stirng                   必填 渲染第一帧图片url
     * @param urls: string[]                    必填 帧图片url 数组
     * @param playType: 'autoPlay' | 'normal';  必填 // 自动播放 | 不自动播放
     * @param playStatus?: 'standby' | 'play' | 'pause' | 'end';  可不填    默认 'standby'  准备 | 播放 | 暂停 | 结束
     * @param currentUrl?:stirng                可不填  当前正在渲染图片url 初始化时 默认和 firstUrl 值 一致
     *
     */
    drawGifImage(params) {
        const gifDraw = new GifDraw({
            ...params,
            parent: this,
        });
        gifDraw.drawGif(this.ctx, this.drawImageOrGifCallBack.bind(this));
        // this.target = gifDraw;
        return gifDraw;
    }
    /**
     * 渲染 image/video/gif 后的callback
     * @param params ItemInfoType
     */
    drawImageOrGifCallBack = (params, isSelected = true) => {
        // const info = {
        //   ...params,
        //   index: params?.index ||  this.index + 1,
        // };
        this.setItemInfo(params, isSelected);
        this.getLayerChangeAttributeCallBack();
        // this.target = info;
    };
    /**
     * 渲染文字
     * @param text: string              必填
     * @param color: string             非必填  默认 #000000 字体颜色，（花字颜色也通过这个参数设置）
     * @param fontSize?: number         非必填 默认 20
     * @param fontFamily?: string       非必填 默认 'Microsoft YaHei'
     * @param textAlign?: string        非必填 默认 'start'
     * @param textBaseline?: string     非必填 默认 'middle';
     * @param fontWeight?: string       非必填 默认 'normal'  字重(非花字) 其他 'normal' | 'bold' | 'lighter'| 'bolder' | '100' ~'900';
     * @param flowerFontWeight?: number       非必填 默认 1  字重(花字)
     * @param isFlowerStrokeText?: boolean       非必填 默认 false  是否是花字;
     * @param opacity?: number          非必填 默认 1  透明度 0 -1;
     */
    drawText = (params) => {
        const drawText = new TextDraw({
            isCreate: true,
            ...params,
            callback: this.drawImageOrGifCallBack,
            ctx: this.ctx,
            parent: this,
        });
        // 处理添加图层后，传数据给右侧属性栏
        this.getLayerChangeAttributeCallBack();
        return drawText;
    };
    // // 按照比例设置文字大小
    // setFontSizeByScale() {
    //   if (this.current.type === "text") {
    //     const scale = this.current?.newFramework?.width / this.current?.self?.baseWidth;
    //     if (this.current && this.current.self && this.current.self.fontSize) {
    //       // this.current.self.fontSize = this.current?.self?.baseFontSize * scale;
    //       this.current.self?.setFontSizeWhenScale(
    //         this.current?.self?.baseFontSize * scale
    //       );
    //     }
    //     // this.current.self?.getTextWidth(false);
    //     // this.autoSetFrameBoxPosition('editText')
    //     // 处理添加图层后，传数据给右侧属性栏
    //     this.getLayerChangeAttributeCallBack();
    //   }
    // }
    /**
     * 设置字体字号
     * @param size: number
     */
    setTextSize = (size) => {
        this.target?.self?.setFontSize(size);
        this.target?.self.autoSetFrameBoxPosition();
        // 处理添加图层后，传数据给右侧属性栏
        this.getLayerChangeAttributeCallBack();
    };
    /**
     * 设置字族
     * @param name：string;
     * @param id：string;
     */
    setTextFamily = (name, id) => {
        if (id) {
            const item = this.drawList.find((x) => x.id === id);
            if (item && item.type === "text") {
                item?.self?.setFontFamily(name);
                this.selectedTarget(item);
                // this.setCurrentAndTarget(item);
                item?.self?.autoSetFrameBoxPosition();
            }
        }
        else {
            if (this.target && this.target.type === "text") {
                this.target?.self?.setFontFamily(name);
                this.target?.self?.autoSetFrameBoxPosition();
            }
        }
        // 处理添加图层后，传数据给右侧属性栏
        this.getLayerChangeAttributeCallBack();
    };
    /**
     * 设置字体颜色
     * @param color;
     */
    setTextColor = (color, id) => {
        if (id) {
            const item = this.drawList.find((x) => x.id === id);
            if (item && item.type === "text") {
                item?.self?.setFontColor(color);
                this.selectedTarget(item);
                // this.setCurrentAndTarget(item)
            }
        }
        else {
            if (this.target && this.target.type === "text") {
                this.target?.self?.setFontColor(color);
            }
        }
        // 处理添加图层后，传数据给右侧属性栏
        this.getLayerChangeAttributeCallBack();
    };
    /**
     * 修改文字
     *
     */
    editText = (text, id, isSelected = true) => {
        if (id) {
            const item = this.drawList.find((x) => x.id === id);
            if (item && item.type === "text") {
                if (item.self.text !== text) {
                    item?.self?.editText(text);
                    isSelected && this.selectedTarget(item);
                    // isSelected && this.setCurrentAndTarget(item);
                    item.self.autoSetFrameBoxPosition();
                    // 处理添加图层后，传数据给右侧属性栏
                    isSelected && this.getLayerChangeAttributeCallBack();
                }
            }
        }
        else {
            if (this.target && this.target.type === "text") {
                if (this.target.self.text !== text) {
                    this.target?.self?.editText(text);
                    this.target?.self.autoSetFrameBoxPosition();
                    // 处理添加图层后，传数据给右侧属性栏
                    isSelected && this.getLayerChangeAttributeCallBack();
                }
            }
        }
        // this.target?.self.autoSetFrameBoxPosition();
        // // 处理添加图层后，传数据给右侧属性栏
        // isSelected && this.getLayerChangeAttributeCallBack();
    };
    /**
     *  1：修改文字的属性
     *  2：可以使用此方法把普通文字修改为花字
     * @param id: string             非必填  要修改的文字id
    //  * @param text?: string          非必填  要修改的文字
     * @param flowerText: 'none'     必填   花字类型  "none" | "fill" | "stroke" | "both"; 默认 none 不是花字，fill 填充花字无描边 ，stroke描边花字无填充， both即填充又描边
     * @param color?: '#000000'      非必填 花字的填充颜色 （flowerText 为  "fill" | "both" 时生效）默认： '#000000'。
     * @param strokeColor?: '#ffffff'非必填 花字的描边颜色 （flowerText 为  "stroke" | "both" 时生效）默认： '#ffffff'。
     * @param flowerFontWeight?: 1   非必填 花字的字重 ， 1、2、3、4、5、6、7、8、9、10。
     * @param fontSize?: 40          非必填 花字的字号
     * @param fontFamily?: ''        非必填 字族
     * @param isShowShadow?: ''      非必填  默认false 是否显示阴影
     * @param shadowColor?: ''       非必填 阴影颜色  默认 无
     * @param shadowOffsetX?: ''     非必填 阴影X轴偏移量 默认 2
     * @param shadowOffsetY?: ''     非必填 阴影Y轴偏移量 默认 2
     * @param shadowBlur?: ''        非必填 阴影模糊值 默认 1
     * @param fillOpacity?: 0 - 1    非必填 文字填充透明度， 默认值1 number 类型 0 - 1
     * @param strokeOpcity?: 0 - 1   非必填 文字描边透明度 默认值1 number 类型 0 - 1
     * @param shadowOpcity?: 0 - 1   非必填 阴影透明度  默认值1 number 类型 0 - 1
     *
     */
    changeToFlowerText = (params, isSelected = true) => {
        if (params.id) {
            const item = this.drawList.find((x) => x.id === params.id);
            if (item && item.type === "text") {
                item?.self?.changeToFlowerText(params);
                setTimeout(() => {
                    item?.self?.autoSetFrameBoxPosition();
                });
                isSelected && this.selectedTarget(item);
            }
        }
        else {
            if (this.target && this.target.type === "text") {
                this.target?.self?.changeToFlowerText(params);
                setTimeout(() => {
                    this.target?.self?.autoSetFrameBoxPosition();
                });
            }
        }
        // 处理添加图层后，传数据给右侧属性栏
        this.getLayerChangeAttributeCallBack();
    };
    /**
     * 设置 文字动画
     * type: 'in' | 'out',   // 移入还是移出
        annimateType:  "none" | "moveIn" | "fadeIn" | "zoomIn" | "moveOut" | "fadeOut" | "zoomOut",  // 无 | 移入 | 淡入 | 放大 | 移出 | 淡出 | 缩小,
     * annimateDuration?: number; 默认 0.5
     */
    setTextAnnimate(params) {
        if (this.target?.type === "text") {
            this.target?.self?.setTextAnnimate(params);
        }
    }
    setCaptionSwitch = (value) => {
        this.isShowCaption = value;
    };
    /**
     * 播放video
     */
    playVideo = () => {
        if (this.target.type === "video") {
            this.target?.self?.play();
        }
    };
    /**
     * 播放video
     */
    pauseVideo = () => {
        if (this.target.type === "video") {
            this.target?.self?.pause();
        }
    };
    /**
     * 播放Gif
     */
    playGif = () => {
        this.target?.self?.play();
    };
    /**
     * 播放Gif
     */
    pauseGif = () => {
        this.target?.self?.pause();
    };
    getDrawToParams = (draw) => {
        const { point, angle, width, height, baseWH } = draw.newFramework;
        const params = {
            ...draw,
            x: point[0][0],
            y: point[0][1],
            w: width,
            h: height,
            angle: angle,
            scale: width / baseWH[0],
            wScale: width / baseWH[0],
            hScale: height / baseWH[1],
        };
        params.self = {
            ...params.self,
            imageEle: undefined,
            parent: undefined,
            newFramework: undefined,
            video: undefined,
            ele: undefined,
            ctx: undefined,
        };
        delete params?.newFramework?.parent;
        return params;
    };
    getDrawParamsToId = (id) => {
        return this.getDrawToParams(this.drawList.find((x) => x.id === id));
    };
    // 获取/ 导出所有元素数据
    getDrawItem = () => {
        return this.drawList.map(this.getDrawToParams);
    };
    /**
     * 获取插入的 图片 视频 文字 信息后存入list
     * @param params 包含一下参数
     * @param ele 元素
     * @param url 图片url
     * @param x 位置x
     * @param y 位置y
     * @param w 宽
     * @param h 高
     * @param type 类型 image ｜ text｜video
     * @param scale 缩放比例  默认1
     * @param angle 旋转角度  默认0
     */
    setItemInfo(params, isSelected = true) {
        const item = {
            scale: 1,
            angle: params?.newFramework?.angle,
            offsideX: 0,
            offsideY: 0,
            index: params?.self?.index !== undefined
                ? params?.self?.index
                : this.drawList.length,
            currentUrl: undefined,
            urls: undefined,
            ...params,
        };
        this.drawList.push(item);
        if (isSelected) {
            this.selectedTarget(item);
        }
        this.rightActionBox?.hideRightActionBox();
    }
    rightClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let p_x = e.pageX;
        let p_y = e.pageY;
        const position = getDocPosition(this.canvas);
        const scale = this.width / this.canvas.offsetWidth;
        const point = [(p_x - position.x) * scale, (p_y - position.y) * scale];
        const status = this.selectLayer(point);
        if (status === 0) {
            this.rightActionBox?.hideRightActionBox();
            return;
        }
        const rect = document.getElementById(this.parentId).getBoundingClientRect();
        let x = e.clientX - rect.left;
        let y = e.clientY - rect.top;
        // 是否出界
        const cw = this.canvas.clientWidth;
        const ch = this.canvas.clientHeight;
        const [bw, bh] = this.rightActionBoxWH;
        // 溢出右侧
        if (cw - x < bw) {
            x = x - (bw - (cw - x));
        }
        // 移除下面
        if (ch - y < bh) {
            y = y - (bh - (ch - y));
        }
        // return { x, y };
        // const filterTarget = this.filterTarget(x, y);
        if (this.target) {
            this.rightActionBox.updateListAndTarget(this.drawList, this.target);
            this.rightActionBox.checkLayerLevel(this.target);
            this.rightActionBox?.showRightActionBox(y, x);
        }
        return false;
    };
    // /*获取鼠标位置*/
    getMousePos(event) {
        const rect = this.canvas.getBoundingClientRect();
        const x = event.clientX - rect.left * (this.canvas.width / rect.width);
        const y = event.clientY - rect.top * (this.canvas.height / rect.height);
        return { x, y };
    }
    /**
     * 从场景中删除
     * @param frame 图层对象
     * @param isEvent 是否触发事件
     */
    removeId(id, isEvene = true) {
        const frame = this.drawList.filter((w) => w.id === id)[0];
        if (frame) {
            this.remove(frame, isEvene);
        }
    }
    /**
     * 从场景中删除
     * @param frame 图层对象
     * @param isEvent 是否触发事件
     */
    remove(frame, isEvene = true) {
        isEvene && this.emit("DELETE_EVEN", frame);
        this.drawList = this.drawList.filter((item) => {
            return item.id !== frame.id;
        });
        this.current = null;
        this.getLayerChangeAttributeCallBack();
        this.rightActionBox?.hideRightActionBox();
        this.debounceListenerDRAW_CHANGE_EVEN();
        // this.drawCanvas();
    }
    /**
     * selectedTarget
     * 设置当前选中项
     * @param frame 把整个图层对象传进来
     * @param isEvene 是否触发事件
     */
    selectedTargetId = (id, isEvene = true) => {
        const frame = this.drawList.filter((w) => w.id === id)[0];
        if (frame) {
            this.selectedTarget(frame.self, isEvene);
        }
    };
    /**
     * selectedTarget
     * 设置当前选中项
     * @param frame 把整个图层对象传进来
     * @param isEvene 是否触发事件
     */
    selectedTarget = (frame, isEvene = true, lineItem = undefined) => {
        // 渲染模板后，斌且是主动点击时间轴时， 关闭模板状态
        if (!isEvene && this.drawTem) {
            this.drawTem = false;
        }
        // const item = this.drawList.filter((w) => w.id === frame.id)[0];
        // if (item) {
        //   this.target = item;
        //   this.current = item;
        // }
        if (!this.drawTem) {
            this.lineItem = lineItem;
            this.setCurrentAndTarget(frame);
            isEvene && this.emit("SELECT_EVEN", frame);
            this.getLayerChangeAttributeCallBack();
        }
        // this.toTopLayer(frame?.id);
    };
    removeSelectedTarget() {
        this.target = null;
        this.current = null;
        this.getLayerChangeAttributeCallBack();
    }
    //拆分选中功能
    setCurrentAndTarget = (frame) => {
        const item = this.drawList.filter((w) => w.id === frame.id)[0];
        if (item) {
            this.target = item;
            this.current = item;
        }
    };
    /**
     * deleteItemFromList
     * 通过 id 从数组中删除 元素
     * @param id: string
     * @param isEvene: 是否触发事件
     */
    deleteItemFromList = (id, isEvene = true) => {
        this.drawList.forEach((item) => {
            if (item.id === id) {
                if (item?.type === "video") {
                    item?.self?.clearVideo();
                }
                this.remove(item, isEvene);
            }
        });
    };
    clearCanvas = () => {
        this.ctx?.clearRect(0, 0, this.canvas.width, this.canvas.height);
    };
    // 重绘
    drawCanvas = () => {
        try {
            this.clearCanvas();
            const context = this.ctx;
            // 绘制背景
            this.BCIDraw.draw(context, this.canvasType);
            const sortList = this.drawList
                .sort((a, b) => a.index - b.index)
                .sort((a, b) => a.index - b.index);
            context.save();
            this.drawList = sortList.map((target) => {
                if (target.isShow) {
                    if ((target.self?.textType === 'caption' || target.self?.textType === 'caption-ppt') && !this.isShowCaption) {
                        return target;
                    }
                    if (target.self?.draw) {
                        target.self?.draw();
                    }
                    this.draw();
                }
                return target;
            });
            this.ctx?.restore();
        }
        catch (error) {
            console.log('error', error);
        }
    };
    startAnimation() {
        cancelAnimationFrame(this.animationId);
        requestAnimationFrame(this.mainDrawCanvas);
    }
    stopAnimation() {
        cancelAnimationFrame(this.animationId);
    }
    // 更新尺寸
    mainDrawCanvas = (time) => {
        cancelAnimationFrame(this.animationId);
        try {
            this.animationId = requestAnimationFrame(this.mainDrawCanvas);
            if (time - this.lastTime < 1000 / this.fps) {
                //this.fps
                return;
            }
            this.drawCanvas();
            this.lastTime = time;
        }
        catch (error) {
            console.log('error', error);
        }
    };
}
